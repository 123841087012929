import { TaskService } from '@/services/task.service';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { TaskState } from './types';
import { TaskComment, TaskGroupsAssignedModel, TaskWithVariables } from '@/models/task/task';
import { Case } from '@/models/process/case';
import { TaskAssigneeDto, GroupAssigneeDto, CreateTaskAttachementDto } from '@/dto/task.dto';
import { ProcessInstanceEntity } from '@/models/process/process-instance';
import { VariableDto } from '@/dto/variables.dto';
import { Variable } from '@/models/process/variable';
import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";

export const actions: ActionTree<TaskState, RootState> = {
  async loadTasks(context) {
    await TaskService.getInstance(this.getters.getUser.access_token)
      .loadTasksPost()
      .then(async (response) => {
        if (response.data) {
          context.dispatch('setTasks', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  async loadTaskById(context, payload: string) {
    await TaskService.getInstance(this.getters.getUser.access_token)
      .loadTasksById(payload)
      .then(async (response) => {
        if (response.data) {
          context.dispatch('setTask', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadTasksForCase(context, payload: string) {
    TaskService.getInstance(this.getters.getUser.access_token)
      .loadTasksForCase(payload)
      .then((response) => {
        if (response.data) {
          context.dispatch('setTasks', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  updateTaskVariables(context, process: ProcessInstanceEntity) {
    let taskVars: Map<string, VariableDto> = new Map()
    process.variables.forEach((variable: Variable) => {
      const varValue: VariableDto = {
        value: variable.value,
        type: variable.type
      }
      taskVars.set(variable.name, varValue)
    });
    TaskService.getInstance(this.getters.getUser.access_token)
      .updateTaskVariables(process.current_task.id, taskVars)
      .then((response) => {
        if (response.apiError) {
          Vue.$toast("Erreur lors de l'enrégistrement de la procédure!", { type: TYPE.ERROR });
        }else{
          Vue.$toast("Procédure enrégistrer avec succès!");
        }
      });
  },

  setTasksForCase(context, payload: string) {
    context.commit('setTasksForCase', payload);
  },

  loadTask(context, payload: string) {
    TaskService.getInstance(this.getters.getUser.access_token)
      .loadTask(payload)
      .then((response) => {
        if (response.data) {
          context.dispatch('setTask', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  assignGroupToTask(context, payload: GroupAssigneeDto) {
    TaskService.getInstance(this.getters.getUser.access_token)
      .assignGroupToTask(payload)
      .then((response) => {
        if(response.apiError){
          Vue.$toast("Une erreur s'est produite, le groupe n'a pas pu être assigné à la tache. Veuillez rééssayer", { type: TYPE.ERROR });
        }else{
          Vue.$toast("L'agent a été bien assigné à la tache");
          context.dispatch(
            'instance/loadProcessInstances',
            null,
            { root: true },
          );
        }
        
      });
  },

  loadtGroupsAssigned(context, payload: string) {
    TaskService.getInstance(this.getters.getUser.access_token)
      .loadTaskGroupsAssigned(payload)
      .then((response) => {
        const groups = response.data.filter((group: TaskGroupsAssignedModel)=>{
          return group.type === 'candidate'
        })
        context.commit('setTaskGroupsAssignee', groups)
      });
  },

  assignUserToTask(context, payload: TaskAssigneeDto) {
    TaskService.getInstance(this.getters.getUser.access_token)
      .assignUserToTask(payload)
      .then((response) => {
        if(response.apiError){
          Vue.$toast("Une erreur s'est produite, l'agent n'a pas pu être assigné à la tache. Veuillez rééssayer", { type: TYPE.ERROR });
        }else{
          Vue.$toast("L'agent a été bien assigné à la tache");
          const processInstance: ProcessInstanceEntity = context.rootGetters['instance/getProcessInstance'];
          const processInstanceRefresh: ProcessInstanceEntity = {
            ...processInstance,
            current_task: {
              ...processInstance.current_task,
              assignee: payload.userId,
            },
          };
          context.dispatch(
            'instance/selectedProcessInstance',
            processInstanceRefresh,
            { root: true },
          );
          context.dispatch(
            'instance/loadProcessInstances',
            null,
            { root: true },
          );
        }
      });
  },

  completeTask(context, payload: string) {
    TaskService.getInstance(this.getters.getUser.access_token)
      .completeTask(payload)
      .then((response) => {
        if(response.data){
          Vue.$toast("Tache validé avec succès!");
          console.log('complete response: ', response);
          return response.data
        }
        if (response.apiError) {
          Vue.$toast("Une erreur s'est produite, la tache n'a pas pu être validé. Veuillez rééssayer", { type: TYPE.ERROR });
          console.log('error');
        }
        
      });
  },

  async loadTaskVariables(context, payload: string) {
    return await TaskService.getInstance(this.getters.getUser.access_token)
      .loadTaskVariables(payload)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadComments(context, payload: string) {
    TaskService.getInstance(this.getters.getUser.access_token)
      .loadComments(payload)
      .then((response) => {
        if (response.data) {
          context.dispatch('setComments', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  addComment(context, payload: Record<string, string>) {
    TaskService.getInstance(this.getters.getUser.access_token)
      .newComment(payload)
      .then((response) => {
        if (response.data) {
          context.dispatch(
            'instance/addComment',
            response.data,
            { root: true },
          );
          // context.commit('addCommentToComments', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  // loadTaskAttachments(context, payload: Record<string, string>) {
  //   TaskService.getInstance(this.getters.getUser.access_token)
  //     .newTaskAttachment(payload)
  //     .then((response) => {
  //       if (response.data) {
  //         context.commit('addCommentToComments', response.data);
  //         return response.data;
  //       }
  //       if (response.apiError) {
  //         console.log('error');
  //       }
  //     });
  // },

  loadTaskAttachment(context, payload: Record<string, string>) {
    TaskService.getInstance(this.getters.getUser.access_token)
      .loadTaskAttachment(payload)
      .then((response) => {
        if (response.data) {
          context.commit('addCommentToComments', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  addTaskAttachment(context, payload: CreateTaskAttachementDto) {
    console.log('request send')
    TaskService.getInstance(this.getters.getUser.access_token)
      .newTaskAttachment(payload)
      .then((response) => {
        if (response.data) {
          console.log('actions: ', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  setTasks(context, payload: Array<TaskWithVariables>) {
    context.commit('setTasks', payload);
  },

  setTask(context, payload: TaskWithVariables | undefined) {
    context.commit('setTask', payload);
  },

  setCases(context, payload: Array<Case>) {
    context.commit('setCases', payload);
  },

  setCase(context, payload: Case | undefined) {
    context.commit('setCase', payload);
  },

  setComments(context, payload: Array<TaskComment>) {
    context.commit('setComments', payload);
  },

  setComment(context, payload: TaskComment | undefined) {
    context.commit('setComment', payload);
  },
};
