
import { Vue } from "vue-property-decorator";
export default Vue.extend({
  name: "ManagementLayout",
  components: {
  },
  data(){
    return {
    }
  },
  computed: {
  },
  methods: {
  },
});
