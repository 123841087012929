import { MutationTree } from 'vuex';
import { ProcessState } from './types';
import { ProcessDefinitionEntity } from '@/models/process/process-definition';

export const mutations: MutationTree<ProcessState> = {
  setProcessesDefinition(
    state,
    payload: ProcessDefinitionEntity[] | undefined,
  ) {
    state.processesDefinition = payload;
  },

  setProcess(state, payload: any | undefined) {
    state.process = payload;
  },
};
