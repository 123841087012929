import { render, staticRenderFns } from "./text-with-label.vue?vue&type=template&id=766ed1d1&scoped=true"
import script from "./text-with-label.vue?vue&type=script&lang=ts"
export * from "./text-with-label.vue?vue&type=script&lang=ts"
import style0 from "./text-with-label.scss?vue&type=style&index=0&id=766ed1d1&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "766ed1d1",
  null
  
)

export default component.exports