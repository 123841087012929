
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from "vuex";
import TableFilter from '@/components/table/table-filter/table-filter.vue';
import TableView from '@/components/table/table-view/table-view.vue';
import List from '@/components/modals/list/list.vue';
import service from '@/json/service_data.json';
import process from '@/json/process_data.json';
export default Vue.extend({
    name: 'AssignmentService',
    components: {
        TableFilter,
        TableView,
        List
    },
    data() {
        return {
            services: service,
            processList: process,
            fields: [
                { key: "name", label: "Nom du service", sortable: false },
                { 
                    key: "description", label: "Description du groupe", sort: false, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.attributes.description ? item.attributes.description[0] : 'Non défini'  
                    } 
                },
                { 
                    key: "chef", label: "Chef service", sort: false, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.attributes.chef ? item.attributes.chef[0] : 'Non défini'  
                    } 
                },
                { 
                    key: "sigle", label: "Sigle service", sort: false, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.attributes.sigle ? item.attributes.sigle[0] : 'Non défini'  
                    } 
                },
                { key: "affected_process", label: "Liste des processus affectés", sortable: false, tdClass: "center_element" },
                { key: "affect_process", label: "Groupe d'affectation", sortable: false, tdClass: "center_element" },
            ],
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: []
        }
    },
    computed: {
        ...mapGetters("keycloak", ['getBusinessServices']),
    },
    methods: {
        showProcessDetail(process: Record<string, unknown>){
            alert('detail: '+ process.name)
        },
        removeProcess(process: Record<string, unknown>) {
            this.processList = this.processList.filter((item)=> item.name !== process.name)
        },
        showProcessList(){
            //here, i'm using directly the process json file.
            this.$bvModal.show('service-process-list')
        },
        filtered() {
            console.log('filtering')
        },
    }
});
