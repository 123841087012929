import { HttpBaseService } from '@/services/httpBase.service';
import { processServicesApiUrl } from '@/app.config';
import { ApiResponse } from '@/models/http/ApiResponse';
import { Task, TaskAttachment, TaskComment, TaskGroupsAssignedModel, TaskWithVariables } from '@/models/task/task';
import { CreateTaskAttachementDto, GroupAssigneeDto, TaskAssigneeDto } from '@/dto/task.dto';
import { VariableDto } from '@/dto/variables.dto';

export class TaskService extends HttpBaseService {
  private static classInstance?: TaskService;
  static apiBaseUrl = processServicesApiUrl;

  constructor(token: string) {
    super(token, TaskService.apiBaseUrl);
  }

  public static getInstance(token: string): TaskService {
    if (!this.classInstance) {
      this.classInstance = new TaskService(token);
    }

    return this.classInstance;
  }

  public loadTasks(
    payload: Record<string, string>,
  ): Promise<ApiResponse<Task[]>> {
    const apiResponse = new ApiResponse<Task[]>();
    //const endpoint = "/task?taskAssignee=" + payload.assignee + "&delegationState=RESOLVED"
    const endpoint = '/engine-rest/task?assignee=' + payload.assignee;
    return this.instance
      .get(endpoint)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadTasksPost(): Promise<ApiResponse<TaskWithVariables[]>> {
    const apiResponse = new ApiResponse<TaskWithVariables[]>();
    return this.instance
      .post('/openmairie/tasks')
      .then((response) => {
        console.log('tasks: ',response.data)
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadTasksById(payload: string): Promise<ApiResponse<Task[]>> {
    const apiResponse = new ApiResponse<Task[]>();
    return this.instance
      .get(`/openmairie/tasks/${payload}`)
      .then((response) => {
        console.log('task: ',response.data)
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadTasksForCase(payload: string): Promise<ApiResponse<Task[]>> {
    const apiResponse = new ApiResponse<Task[]>();
    const endpoint = '/task?processInstanceId=' + payload;
    return this.instance
      .get(endpoint)
      .then((response) => {
        apiResponse.data = response.data;
        console.log('tasks loaded for instance :', response.data);
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadTask(payload: string): Promise<ApiResponse<Task>> {
    const apiResponse = new ApiResponse<Task>();
    return this.instance
      .get(`/task/id=${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public assignGroupToTask(payload: GroupAssigneeDto): Promise<ApiResponse<Task>> {
    const apiResponse = new ApiResponse<Task>();
    return this.instance
      .post('/openmairie/task/assign-group', payload)
      .then((response) => {
        console.log('response: ', response);
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public assignUserToTask(payload: TaskAssigneeDto): Promise<ApiResponse<Task>> {
    const apiResponse = new ApiResponse<Task>();
    const request_data = {
      userId: payload.userId,
      type: 'assignee'
    };
    return this.instance
      .post(`/engine-rest/task/${payload.taskId}/identity-links`, request_data)
      .then((response) => {
        console.log('response: ', response);
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadTaskGroupsAssigned(payload: string): Promise<ApiResponse<Array<TaskGroupsAssignedModel>>>{
    const apiResponse = new ApiResponse<Array<TaskGroupsAssignedModel>>();
    return this.instance
      .get(`/engine-rest/task/${payload}/identity-links`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public completeTask(payload: string): Promise<ApiResponse<any>> {
    const apiResponse = new ApiResponse<any>();
    const requestData = {};
    return this.instance
      .post(`/engine-rest/task/${payload}/complete`, requestData)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public updateTaskVariables(taskId: string, payload: Map<string, VariableDto>): Promise<ApiResponse<Task>> {
    const apiResponse = new ApiResponse<Task>();
    return this.instance
      .post(`/engine-rest/task/${taskId}/variables`, payload)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadTaskVariables(payload: string): Promise<ApiResponse<Task>> {
    const apiResponse = new ApiResponse<Task>();
    return this.instance
      .get(`/task/${payload}/variables`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadComments(payload: string): Promise<ApiResponse<TaskComment[]>> {
    const apiResponse = new ApiResponse<TaskComment[]>();
    return this.instance
      .get(`/task/${payload}/comment`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public newComment(
    payload: Record<string, string>,
  ): Promise<ApiResponse<TaskComment>> {
    const apiResponse = new ApiResponse<TaskComment>();

    const requestData = {
      processInstanceId: payload.processInstanceId,
      message: payload.message,
    };
    return this.instance
      .post(`/engine-rest/task/${payload.id}/comment/create`, requestData)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadTaskAttachments(
    payload: string,
  ): Promise<ApiResponse<TaskComment[]>> {
    const apiResponse = new ApiResponse<TaskComment[]>();
    return this.instance
      .get(`/task/${payload}/attachment`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadTaskAttachment(
    payload: Record<string, string>,
  ): Promise<ApiResponse<TaskComment>> {
    const apiResponse = new ApiResponse<TaskComment>();
    return this.instance
      .get(`/engine-rest/task/${payload.taskId}/attachment/${payload.attachmentId}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public newTaskAttachment(
    payload: CreateTaskAttachementDto,
  ): Promise<ApiResponse<TaskAttachment>> {
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const mediaForm = new FormData();
    mediaForm.append('file_name', payload.file_name);
    payload.files.forEach((value) => {
      mediaForm.append('medias', value);
    });

    const apiResponse = new ApiResponse<TaskAttachment>();
    const requestData = {
      data: payload.files[0],
    };
    return this.instance
      .post(`/engine-rest/task/${payload.task_id}/attachment/create`, mediaForm, { headers: headers })
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }
}
