
import { Vue } from "vue-property-decorator";
import { extend } from 'vee-validate';
import inputSelect from "@/components/form/form-select/input-select.vue";
import inputText from "@/components/form/form-input/input-text.vue";
import fields from '@/json/form.json';
import { FieldModel } from "@/models/field/field";

extend('even', value => {
  return value % 2 === 0;
});

extend('even_2', value => {
  return ((value >= 5) && (value <= 10));
});

export default Vue.extend({
    name: "PageTest",
    components:{
        inputSelect,
        inputText
    },
    data(){
        return {
            formField: {} as FieldModel,
            formFields: new Array<FieldModel>(),
            value: ''
        }
    },
    computed: {
    
    },
    methods: {
        
    },
    beforeMount() {
        this.formFields = fields
    }
})
