import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/task/task-getters";
import {actions} from "@/store/modules/task/task-actions";
import {mutations} from "@/store/modules/task/task-mutations";
import { TaskState } from "./types";
export const state: TaskState = {
    tasks: [],
    tasksForCase: [],
    task: undefined,
    cases: [],
    case: undefined,
    comment: undefined,
    comments: [],
    taskGroupsAssignee: []
};

export const task: Module<TaskState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};