import { PersonModel } from '@/models/proposition';
import { MutationTree } from 'vuex';
import { KeycloakState } from './types';
import { Group } from '@/models/identity/group';
import { UserModel } from '@/models/person/person';

export const mutations: MutationTree<KeycloakState> = {
  setKeycloakProcessManagerData(state, payload: Group | undefined) {
    state.keycloakProcessManagerData = payload;
  },

  setBusinessServices(state, payload: Array<Group>) {
    state.businessServices = payload;
    state.groupsDropdown = payload.map((group: Group) => {
      return {
        value: group.id,
        text: group.name,
      };
    });
  },

  setBusinessService(state, payload: Group | undefined) {
    state.businessService = payload;
  },

  setGroups(state, payload: Array<Group>) {
    state.groups = payload;
  },

  setGroup(state, payload: Group | undefined) {
    state.group = payload;
  },

  setGroupMembers(state, payload: Array<PersonModel>) {
    state.groupMembers = payload;
  },

  setGroupMember(state, payload: PersonModel | undefined) {
    state.groupMember = payload;
  },

  setUsers(state, payload: Array<UserModel>) {
    state.users = payload;
    state.usersDropdown = payload.map((user: UserModel) => {
      if (!user.email) {
        user.email = 'none';
      }
      return {
        value: user.email,
        text: user.first_name + ' ' + user.last_name,
      };
    });
  },
};
