
import { Vue } from 'vue-property-decorator';
import InputText from '@/components/form/form-input/input-text.vue';
import InputTextarea from '@/components/form/form-textarea/input-textarea.vue'
export default Vue.extend({
    name: 'MemoCaseForm',
    components: {
        InputText,
        InputTextarea
    },
    data() {
        return {
            beneficiary: '',
            contact: '',
            applicant: '',
            message: ''
        }
    },
    methods: {
    }
});
