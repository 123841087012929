
import { Vue } from 'vue-property-decorator';
import InputText from '@/components/form/form-input/input-text.vue';
import InputSelect from '@/components/form/form-select/input-select.vue';
import InputTextarea from '@/components/form/form-textarea/input-textarea.vue';
import {mapActions, mapGetters} from 'vuex'
export default Vue.extend({
    name: 'MemoCreation',
    components: {
        InputText,
        InputSelect,
        InputTextarea
    },
    data() {
        return {
            showGroupSelectInput: false,
            labelText: 'ou groupe bénéficiaire',
            affectedTo: '',
            affectedGroup: ''
        }
    },
    computed:{
        ...mapGetters('keycloak', ['getUsersDropdown', 'getGroups']),
    },
    methods: {
        onLabelSelectClick(){
            this.showGroupSelectInput = !this.showGroupSelectInput
            if(this.showGroupSelectInput){
                this.labelText = 'ou personnel bénéficiaire'
            }else{
                this.labelText = 'ou groupe bénéficiaire'
            }
        }
    }
});
