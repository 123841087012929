
import { Vue } from 'vue-property-decorator';
export default Vue.extend({
    name: 'Tab',
    data() {
        return {
            tabIndex: 0,
        }
    },
    watch: {
        tabIndex(state: number) {
            this.$emit("input", this.tabIndex);
            //this.tabIndex = state
        }
    }
});
