
import { Vue } from 'vue-property-decorator';
import TableFilter from '@/components/table/table-filter/table-filter.vue';
import TableView from '@/components/table/table-view/table-view.vue';
import process from '@/json/process_data.json';
import { mapGetters } from 'vuex';
import {ProcessDefinitionEntity} from "@/models/process/process-definition";
import moment from "moment";
export default Vue.extend({
    name: 'Process',
    components: {
        TableFilter,
        TableView
    },
    data() {
        return {
            processes: process,
            fields: [
                { key: "name", label: "Libellé", sortable: false, formatter: (value: null, key: string, item: ProcessDefinitionEntity) => {
                    return item.process_definition.name
                }},
                { key: "description", label: "Description", sortable: false, formatter: (value: null, key: string, item: ProcessDefinitionEntity) => {
                  return item.process_definition.description
                } },
                { key: "category", label: "Catégorie", sortable: false, formatter: (value: null, key: string, item: ProcessDefinitionEntity) => {
                    let category = item.properties.find((property: any) => property.name === 'category')
                    return category ? category.value : ''
                } },
                { key: "owner", label: "Service d'affectation", sortable: false, formatter: (value: null, key: string, item: ProcessDefinitionEntity) => {
                    let owner = item.properties.find((property: any) => property.name === 'owner')
                    return owner ? owner.value : ''
                  } },
                { key: "version", label: "Version", sortable: false, formatter: (value: null, key: string, item: ProcessDefinitionEntity) => {
                  return item.process_definition.version
                } },
                { key: "creation_date", label: "Date de création", sortable: false, formatter: (value: null, key: string, item: ProcessDefinitionEntity) => {
                    return moment(item.deployment.deploymentTime).format('YYYY-MM-DD')
                  } },
            ],
            processFilter: {
                service: 'service',
                group: 'group',
                category: 'category'
            },
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: [],
            busyState: true
        }
    },
    computed:{
        ...mapGetters("process", ['getProcessesDefinition'])
    },
    methods: {
        filtered() {
            console.log('filtering')
        },
    },
    mounted() {
        console.log("Process mounted")
    }
});
