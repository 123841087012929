
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
export default Vue.extend({
    name: 'Message',
    props: [
        'messageOwner',
        'messageContent',
        'messageType',
        'messageDate'
    ],
    components: {
        
    },
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters('keycloak', ['getUsers']),
        owner(): string{
            if(!this.findUser() || this.findUser() === undefined){
                return '-'
            }
            return this.findUser().last_name +' '+ this.findUser().first_name
        }
    },
    methods: {
        findUser(): Record<string, string> | null | undefined{
            return this.getUsers.find((user)=> this.messageOwner === user.email)
        }
    }
});
