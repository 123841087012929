import { HttpBaseService } from '@/services/httpBase.service';
import { processServicesApiUrl } from '@/app.config';
import { ApiResponse } from '@/models/http/ApiResponse';
import { Comment } from '@/models/comment/comment';
import { ProcessDefinitionEntity } from '@/models/process/process-definition';
import { ProcessInstanceEntity } from '@/models/process/process-instance';

export class ProcessService extends HttpBaseService {
  private static classInstance?: ProcessService;
  static apiBaseUrl = processServicesApiUrl;

  constructor(token: string) {
    super(token, ProcessService.apiBaseUrl);
  }

  public static getInstance(token: string): ProcessService {
    if (!this.classInstance) {
      this.classInstance = new ProcessService(token);
    }

    return this.classInstance;
  }

  public async loadProcessesDefinition(): Promise<
    ApiResponse<ProcessDefinitionEntity[]>
  > {
    const apiResponse = new ApiResponse<ProcessDefinitionEntity[]>();
    return await this.instance
      .get('/openmairie/process/definitions')
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public loadProcessById(payload: string): Promise<ApiResponse<[]>> {
    const apiResponse = new ApiResponse<[]>();
    return this.instance
      .get(`/process-definition/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public async loadProcessInstances(): Promise<ApiResponse<ProcessInstanceEntity[]>> {
    const apiResponse = new ApiResponse<ProcessInstanceEntity[]>();
    try {
      const response = await this.instance.post('/openmairie/process/instances');
      apiResponse.data = response.data;
      return apiResponse;
    } catch (error) {
      apiResponse.apiError = error.response.data;
      return apiResponse;
    }
  }

  public async loadProcessInstanceById(payload: string): Promise<ApiResponse<ProcessInstanceEntity>> {
    const apiResponse = new ApiResponse<ProcessInstanceEntity>();
    try {
      const response = await this.instance.get(`/openmairie/process/instances/${payload}`);
      apiResponse.data = response.data;
      return apiResponse;
    } catch (error) {
      apiResponse.apiError = error.response.data;
      return apiResponse;
    }
  }

  public getProcessInstanceComments(
    payload: string,
  ): Promise<ApiResponse<Comment[]>> {
    const apiResponse = new ApiResponse<Comment[]>();
    return this.instance
      .get(`/process-instance/${payload}/comment`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }
}
