import { MutationTree } from 'vuex';
import { InstanceState } from './types';
import { Comment } from '@/models/comment/comment';
import { ProcessInstanceEntity } from '@/models/process/process-instance';

export const mutations: MutationTree<InstanceState> = {
  setProcessInstances(state, payload: ProcessInstanceEntity[]) {
    state.instances = payload;
  },

  setProcessInstance(state, payload: ProcessInstanceEntity | undefined) {
    state.instance = payload;
  },

  setComments(state, payload: Comment[] | undefined) {
    state.comments = payload;
  },

  setProcessInstanceComments(state, payload: Comment[] | undefined) {
    state.comments = payload;
  },

  addCommentToComments(state, payload: Comment) {
    state.comments.push(payload)
  },

  updateProcessInstanceState(state, payload: Record<string, string>){
    const instance = state.instances.find((instance: ProcessInstanceEntity) => instance.process_instance.id === payload.id)
    state.instance = {
      ...instance,
      process_instance: {
        ...instance.process_instance,
        state: payload.state
      }
    }
    state.instances.splice(state.instances.indexOf(instance), 1, state.instance)
  }

};
