import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { KeycloakState } from './types'
import { PersonModel } from "@/models/proposition";
import { Group } from "@/models/identity/group";


export const getters: GetterTree<KeycloakState, RootState> = {
    getKeycloakProcessManagerData(state): Group | undefined{
        return state.keycloakProcessManagerData
    },

    getBusinessServices(state): Array<Group> {
        return state.businessServices
    },

    getBusinessService(state): Group | undefined{
        return state.businessService
    },
    
    getGroups(state): Array<Group> {
        return state.groups
    },

    getGroup(state): Group | undefined{
        return state.group
    },

    getGroupsDropdown(state): Array<Record<string, string>> {
        return state.groupsDropdown
    },

    getGroupMembers(state): Array<PersonModel> {
        return state.groupMembers
    },

    getGroupMember(state): PersonModel | undefined {
        return state.groupMember
    },

    getUsersDropdown(state): Array<Record<string, string>> {
        return state.usersDropdown
    },

    getUsers(state): any {
        return state.users
    },
};