import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/keycloak/keycloak-getters";
import {actions} from "@/store/modules/keycloak/keycloak-actions";
import {mutations} from "@/store/modules/keycloak/keycloak-mutations";
import { KeycloakState } from "./types";
export const state: KeycloakState = {
    groups: [],
    group: undefined,
    groupMembers: [],
    groupMember: undefined,
    keycloakProcessManagerData: undefined,
    businessService: undefined,
    businessServices: [],
    businessServiceMember: undefined,
    businessServiceMembers: [],
    user: undefined,
    users: [],
    usersDropdown: [],
    groupsDropdown: []
};

export const keycloak: Module<KeycloakState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};