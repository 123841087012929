
import { Vue } from 'vue-property-decorator';
import { appMixin } from '@/mixins/app.mixin';

export default Vue.extend({
  name: 'TableView',
  props: [
    'tableItems',
    'fixed',
    'fields',
    'filter',
    'id',
    'currentPage',
    'perPage',
    'detailRow',
    'rowSelection',
    'theAnotherPage',
    'tableConfig',
    'filterIncludedFields',
    'tableName',
  ],
  mixins: [appMixin],
  data() {
    return {
      rowSelected: '',
      transProps: {
        // Transition name
        name: 'flip-list',
      },
    };
  },
  computed: {},
  methods: {
    onFiltered(filteredItems: Record<string, unknown>[]) {
      this.$emit('onFiltered', filteredItems);
    },
    onRowClicked(item: any) {
      if (this.tableName === 'process') {
        this.goToAnotherPage(this.theAnotherPage);
      } else {
        this.$emit('onTableRowClicked', item);
      }
    },
    goToAnotherPage(page: string) {
      this.$router.push(page);
    },
    openProcessList(item: Record<string, unknown>) {
      this.$emit('onProcessList', item);
    },
    openGroupMemberList(item: Record<string, unknown>) {
      this.$emit('onMemberList', item);
    },
  },
  watch: {
    isBusy() {
      console.log('isBusy', this.isBusy);
      this.$emit('input', this.isBusy);
    },
  },
});
