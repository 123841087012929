import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { InstanceState } from './types';
import { Comment } from '@/models/comment/comment';
import { ProcessInstanceEntity } from '@/models/process/process-instance';

export const getters: GetterTree<InstanceState, RootState> = {
  getProcessInstances(state): ProcessInstanceEntity[] {
    return state.instances;
  },

  getProcessInstance(state): ProcessInstanceEntity | undefined {
    return state.instance;
  },

  getComments(state): Comment[] {
    return state.comments;
  },
};
