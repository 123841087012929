
import { Vue } from "vue-property-decorator";
import InputSelect from '@/components/form/form-select/input-select.vue';
import InputText from '@/components/form/form-input/input-text.vue';
export default Vue.extend({
    name: 'FormOverview',
    components: {
        InputSelect,
        InputText
    },
    props: [
        'id',
        'reference',
        'title',
        'items',
    ],
    data() {
        return { 
            list: this.items,
            selected: '',
            form: {
                processName: '',
                processDescription: '',
                category: '',
                categoryOptions: [],
                service: '',
                serviceOptions: [],
                group: '',
                groupOptions: []
            }
        }
    },
    computed: {
    },
    methods: {
        itemDetail(item: Record<string, unknown>) {
            this.$emit('showDetail', item)
        },
        itemRemove(item: Record<string, unknown>) {
            this.$emit('removeItem', item)
        }
    },
    watch: {
        items(){
            this.list = this.items
        }
    }
})
