
import { Vue } from 'vue-property-decorator';
import InputText from '@/components/form/form-input/input-text.vue';
import InputSelect from '@/components/form/form-select/input-select.vue';
export default Vue.extend({
    name: 'ProcessStep',
    props: [
        'stepTitle',
        'id'
    ],
    components: {
        InputText,
        InputSelect
    },
    data() {
        return {
            stepName: '',
            stepDescription: '',
            stepGroupAssigned: '',
            stepNumber: '',
            stepGroupPerformed: '',
            terminateAction: '',
            serviceOptions: '',
            groupOptions: '',
            accordionState: false,
            stepFormItem: [
                {
                    name: 'Nom',
                    type: 'Texte',
                    description: 'Nom du instance',
                },
                {
                    name: 'Groupe',
                    type: 'Selection',
                    description: 'Groupe d\'affectation',
                },
            ],
            fields: [
                { key: "name", label: "Nom du champ", sortable: false },
                { key: "type", label: "Type du champ", sortable: false },
                { key: "description", label: "Description du champ", sortable: false },
                { key: "actions", label: "Actions", sortable: false, tdClass: "center_element" },
            ],
        }
    },
    computed: {
        openOrClose(): boolean {
            return this.accordionState
        }
    },
    methods: {
        accordionToggle() {
            this.accordionState = !this.accordionState
        }
    }
});
