export const layoutMixin = {
  computed: {
  },
  data() {
    return {
      sidebarItems: [
        {
          id: '/task',
          link: '/task',
          titleMenu: 'Mes Tâches',
          icon: 'dashboard' 
        },
        // {
        //   id: '/memos',
        //   titleMenu: 'Mémos',
        //   link: '/memos',
        //   icon: 'event_available',
        // },
        {
          id: '/instance',
          link: '/instance',
          titleMenu: 'Gestion des demandes',
          icon: 'edit_calendar',
        },
        {
          id: '/administration',
          link: '/administration',
          titleMenu: 'Administration',
          icon: 'edit_calendar',
        }
      ],
    };
  },
  methods: {
  },
};