
import { Vue } from 'vue-property-decorator';
import FormSelect from '@/components/form/form-select/form-select.vue';
import FormInput from '@/components/form/form-input/form-input.vue';
export default Vue.extend({
    name: 'TableFilter',
    props: [
        'filterItems',
        'canCreate'
    ],
    components: {
        FormSelect,
        FormInput
    },
    data() {
        return {
            searchText:'',
            serviceSelected: null,
            categorySelected: null,
            groupSelected: null,
            stateSelected: null,
            perPage: 10,
            serviceOptions: [
                { value: null, text: 'Selectionnez un service' },
                { value: 'service_1', text: 'Service 1' },
                { value: 'service_2', text: 'Service 2' },
                { value: 'service_3', text: 'Service 3' },
            ],
            categoryOptions: [
                { value: null, text: 'Selectionnez une catégorie' },
                { value: 'category_1', text: 'catégorie 1' },
                { value: 'category_2', text: 'catégorie 2' },
                { value: 'category_3', text: 'catégorie 3' },
            ],
            groupOptions: [
                { value: null, text: 'Selectionnez un groupe' },
                { value: 'group_1', text: 'groupe 1' },
                { value: 'group_2', text: 'groupe 2' },
                { value: 'group_3', text: 'groupe 3' },
            ],
            perPageOptions: [
                { value: 10, text: '10' },
                { value: 20, text: '20' },
                { value: 50, text: '50' },
                { value: 100, text: '100' },
            ],
            stateOptions: [
                { value: null, text: 'Selectionnez un état' },
                { value: 'opend', text: 'Ouvert' },
                { value: 'in_progress', text: 'En cours' },
                { value: 'closed', text: 'Cloturé' },
            ]
        }
    },
    methods: {
        showing() {
            alert(this.searchText)
        }
    }
});
