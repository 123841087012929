
import { Vue } from "vue-property-decorator";
import { mapGetters, mapActions } from 'vuex'
import FormVueSelect from '@/components/form/form-select/form-v-select.vue';

export default Vue.extend({
    name: 'UserModal',
    components: {
        FormVueSelect
    },
    props: [
        'id',
        'reference',
        'affectedUser',
        'task'
    ],
    data() {
        return { 
            affectedTo: this.affectedUser,
            validateBtnDiseable: true
        }
    },
    computed: {
        ...mapGetters('keycloak', ['getGroups', 'getGroupsDropdown']),
        ...mapGetters('instance', ['getProcessInstance'])
    },
    methods: {
        ...mapActions('task', ['assignGroupToTask']),
        onValidated(){
            this.assignGroupToTask({
                group: this.affected_to,
                task_id: this.task.id, 
                process_definition_id: this.task.processDefinitionId
            }).then(()=>{
                console.log('request ok')
            })
            this.$root.$emit('bv::hide::modal', 'group-modal', '#btnShow')
        },
        onAssigneeChange(payload: string){
            this.affected_to = payload
            this.validateBtnDiseable = false 
        },
        modalShow(){
            this.validateBtnDiseable = true
            this.affected_to = this.task.assignee
        }
    },
})
