import { ActionTree } from 'vuex';
import { MessageState } from "./types";
import { RootState } from "@/store/types"
import { messageModel } from '@/models/messages/messages';

export  const actions: ActionTree<MessageState, RootState> = {

    setMessages(context, payload: Array<messageModel>) {
        context.commit("setMessages", payload)
    },

    setMessage(context, payload: messageModel | undefined) {
        context.commit("setMessage", payload)
    }
}