
import { Vue } from 'vue-property-decorator';
import TableView from '@/components/table/table-view/table-view.vue';
import { mapActions, mapGetters } from 'vuex';
import form from '@/json/form.json'
import { FieldModel } from '@/models/field/field';
export default Vue.extend({
    name: 'ProcessElementForm',
    components: {
        TableView
    },
    data() {
        return {
            formElements: form,
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: [],
            filtered: ''
        }
    },
    computed: {
        ...mapGetters("processNotes", ["getMessages"]),
        fields(): Array<Record<string, unknown>> {
            Object.keys(this.formElements[0].step)
            let fields: Array<Record<string, unknown>> = [{key: 'field_name', label: 'Nom du champ'}]
            Object.keys(this.formElements[0].step).forEach((step: string) => {
                fields.push({key: step})
            });
            return fields
        },
        items(): Array<Record<string, unknown>> {
            return this.formElements.map((item) => {
                return {
                    field_name: item.field_name,
                    ...item.step
                }
            })
        }
    },
    methods: {
        rowClicked() {
            this.$bvModal.show('form-element-update') 
        }
    }
});
