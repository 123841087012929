export enum ProcessCaseStatusEnum {
    Opened = 'opened',
    InProgess = 'In Progress',
    Closed =  'closed'
}

export enum FormType {
    Client = 'ClientForm',
    Work = 'WorkForm',
}

export enum TypeOptions {
    Text = 'text',
    Num = 'number',
    Date = 'date',
    Time = 'time',
    Select = 'select'
}

export enum ProcessState {
    Active = 'ACTIVE',
    Cancel = 'SUSPENDED',
    Close = 'EXTERNALLY_TERMINATED'
}