
import { Vue } from "vue-property-decorator";
import { mapGetters, mapActions } from 'vuex'
import FormVueSelect from '@/components/form/form-select/form-v-select.vue';

export default Vue.extend({
    name: 'GroupModal',
    components: {
        FormVueSelect
    },
    props: [
        'id',
        'reference',
        'affectedUser',
        'task'
    ],
    data() {
        return { 
            affectedTo: this.affectedUser,
            validateBtnDiseable: true
        }
    },
    computed: {
        ...mapGetters('keycloak', ['getGroups', 'getUsersDropdown']),
        ...mapGetters('instance', ['getProcessInstance'])
    },
    methods: {
        ...mapActions('task', ['assignUserToTask']),
        onValidated(){
            this.assignUserToTask({
                userId: this.affected_to,
                taskId: this.task.id
            }).then(()=>{
                console.log('request ok')
            })
            this.$root.$emit('bv::hide::modal', 'user-modal', '#btnShow')
        },
        onAssigneeChange(payload: string){
            this.affected_to = payload
            this.validateBtnDiseable = false 
        },
        modalShow(){
            this.validateBtnDiseable = true
            this.affected_to = this.task.assignee
        }
    },
})
