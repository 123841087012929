
import { Vue } from 'vue-property-decorator';
import TableFilter from '@/components/table/table-filter/table-filter.vue';
import TableView from '@/components/table/table-view/table-view.vue';
import List from '@/components/modals/list/list.vue';
import category from '@/json/category.json';
import { ProcessDto } from '@/dto/types';
export default Vue.extend({
    name: 'CaseCategory',
    components: {
        TableFilter,
        TableView,
        List
    },
    data() {
        return {
            categoryProcess: category,
            process_affected: new Array<ProcessDto>(),
            fields: [
                { key: "name", label: "Nom catégorie", sortable: false },
                { key: "description", label: "Description catégorie", sortable: false },
                { key: "affected_process", label: "Processus affectés", sortable: false, tdClass: "center_element" },
                { key: "creation_date", label: "Création", sortable: false },
                { key: "update_date", label: "Dernière date de mise à jour", sortable: false },
                // { key: "affect_process", label: "Affectation processus", sortable: false, tdClass: "center_element" },
            ],
            processFilter: {
                service: 'service'
            },
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: []
        }
    },
    methods: {
        showProcessList(value: any){
            this.process_affected = value.process
            this.$bvModal.show('process-category-list')
        },
        showProcessAffectedDetail(process: Record<string, unknown>){
            alert('detail: '+ process.name)
        },
        removeProcessAffected(process: Record<string, unknown>){
            this.process_affected = this.process_affected.filter((item)=> item.name !== process.name)
        },
        filtered() {
            console.log('filtering')
        },
    }
});
