
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from "vuex";
import AppCollapse from '@/components/app-collapse/app-collapse.vue'
import ProcessNote from '@/components/process/process-builder/process-note/process-note.vue'
import ProcessSidebar from '@/components/process/process-builder/process-sidebar/process-sidebar.vue'
import Tab from '@/components/tab/tab.vue';
import TabItem from '@/components/tab/tab-item/tab-item.vue';
import UserModal from '@/components/modals/user-modal/user-modal.vue'
import GroupModal from '@/components/modals/group-modal/group-modal.vue'
import ProcessForm from '@/components/process/process-builder/process-form/process-form.vue'

export default Vue.extend({
    name: 'UseCaseOverride',
    components: {
        AppCollapse,
        ProcessNote,
        ProcessSidebar,
        Tab,
        TabItem,
        UserModal,
        GroupModal,
        ProcessForm
    },
    data() {
        return {
            selected: '',
            tabIndex: 0,
        }
    },
    computed:{
        ...mapGetters('instance', ['getProcessInstance']), 
        ...mapGetters('task', ['getTask']),
        ...mapGetters(['getUser'])
    },
    methods: {
        ...mapActions('task', ['completeTask', 'updateTaskVariables', 'loadTaskById']),
        ...mapActions('instance', ['activateProcessInstance',
                                    'cancelProcessInstance', 
                                    'closeProcessInstance'
                                ]),
        showUsers(){
            this.$root.$emit('bv::show::modal', 'user-modal', '#btnShow')
        },
        showGroups(){
            this.$root.$emit('bv::show::modal', 'group-modal', '#btnShow')
        },
        onValidateStepClick(){
            this.completeTask(this.getTask.id).then(
                () => {
                    this.routerGoBack()
                }
            )
        },
        onActiveBtnClicked(){
            this.activateProcessInstance(this.getTask.processInstanceId)
        },
        onCancelBtnClicked(){
            this.cancelProcessInstance(this.getTask.processInstanceId)
        },
        onCloseBtnClicked(){
            this.closeProcessInstance(this.getTask.processInstanceId)
        },
        onRegisterClick(){
            this.updateTaskVariables(this.getProcessInstance)
        },
        onCompleteBtnClicked(){
            console.log('completed')
        }

    },
    created() {
       this.loadTaskById(this.$route.params.id)
    },
});
