
  import { Vue } from "vue-property-decorator";
  export default Vue.extend({
    name: "Card",
    props: [
        'title'
    ],
    components: {
    },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
    },
  });
  