
  import { Vue } from "vue-property-decorator";
  export default Vue.extend({
    name: "Sidebar",
    props: [
      'sidebarTitle'
    ],
    components: {
    },
    data(){
      return {
      }
    },
    computed: {
    },
    methods: {
      onButtonClick(){
        this.$emit('buttonClicked',true)
      }
    },
  });
  