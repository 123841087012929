
import { Vue } from 'vue-property-decorator';
import AppHeader from '@/components/app-header/app-header.vue'
import ProcessDefaultInfo from '@/components/process/process-builder/process-default-info/process-default-info.vue'
import MemoCaseForm from '@/components/management-layout/memo-consulting/memo-case-form/memo-case-form.vue';
import ProcessNote from '@/components/process/process-builder/process-note/process-note.vue';
export default Vue.extend({
    name: 'MemoConsulting',
    components: {
        AppHeader,
        ProcessDefaultInfo,
        MemoCaseForm,
        ProcessNote
    },
    data() {
        return {
        }
    },
    methods: {
    }
});
