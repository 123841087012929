
import { Vue } from 'vue-property-decorator';
import ManagementLayout from '@/components/management-layout/management-layout.vue'
import Sidebar from '@/components/management-layout/sidebar/sidebar.vue'
import MemoConsulting from '@/components/management-layout/memo-consulting/memo-consulting.vue'
import memoCreation from '@/components/management-layout/memo-creation/memo-creation.vue';
import Card from '@/components/card/card.vue'
export default Vue.extend({
    name: 'MemoManagement',
    components: {
        ManagementLayout,
        Sidebar,
        Card,
        MemoConsulting,
        memoCreation
    },
    data() {
        return {
            newMemo: true,
            memos: [
                {
                    id: 1,
                    title: 'Mémo #1',
                    owner: 'OKPAOUL Drumont',
                    object: 'Traitement des actes de naissance'
                },
                {
                    id: 2,
                    title: 'Mémo #2',
                    owner: 'OKPAOUL Boguen',
                    object: 'Traitement des actes de mariage'
                },
                {
                    id: 3,
                    title: 'Mémo #3',
                    owner: 'EKPELE Joseph',
                    object: 'Traitement des actes de décès'
                },
            ]
        }
    },
    methods: {
        onMemoClicked(payload: any){
            console.log(payload)
            this.newMemo = false
        },
        newMemoButton(){
            this.newMemo = true
        }
    }
});
