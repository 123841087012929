
import { Vue } from 'vue-property-decorator';
import AppHeader from '@/components/app-header/app-header.vue';
import FormOverview from '@/components/modals/form/form-overview/form-overview.vue';
import FormElementCreation from '@/components/modals/form/form-element/create/create-element.vue'
import FormElementUpdate from '@/components/modals/form/form-element/update/update-element.vue'
import ProcessStep from '@/components/process/process-step/process-step.vue';
import ProcessElementForm from '@/components/process/process-elements-form/process-elements-form.vue';
import InputText from '@/components/form/form-input/input-text.vue';
import InputSelect from '@/components/form/form-select/input-select.vue';
import FormIcon from '@/components/form/form-icon/form-icon.vue';
import { mapActions, mapGetters } from 'vuex';
export default Vue.extend({
    name: 'ProcessManagement',
    components: {
        AppHeader,
        ProcessStep,
        InputText,
        InputSelect,
        FormIcon,
        FormOverview,
        FormElementCreation,
        ProcessElementForm,
        FormElementUpdate
    },
    data() {
        return {
            processName: '',
            processDescription: '',
            category: '',
            categoryOptions: [],
            service: '',
            serviceOptions: '',
            group: '',
            groupOptions: '',
            stepName: '',
            stepDescription: '',
            stepGroupAssigned: '',
            stepNumber: '',
            stepGroupPerformed: '',
            terminateAction: '',
            stepFormItem: [
                {
                    name: 'Nom',
                    type: 'Texte',
                    description: 'Nom du instance',
                },
                {
                    name: 'Groupe',
                    type: 'Selection',
                    description: 'Groupe d\'affectation',
                },
            ],
            fields: [
                { key: "name", label: "Nom du champ", sortable: false },
                { key: "type", label: "Type du champ", sortable: false },
                { key: "description", label: "Description du champ", sortable: false },
                { key: "actions", label: "Actions", sortable: false, tdClass: "center_element" },
            ],
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: []
        }
    },
    computed: {
        ...mapGetters("processNotes", ["getMessages"]),
    },
    methods: {
        ...mapActions("processNotes", ["setMessage"]),
        showWorkForm() {
            this.$bvModal.show('work-form')
        },
        showClientForm(){
            this.$bvModal.show('client-form')
        },
        showFormElementCreationModal() {
           this.$bvModal.show('form-element-creation') 
        }
    }
});
