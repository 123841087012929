
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from "vuex";


export default Vue.extend({
    name: 'TextWithLabel',
    props: [
        'label',
        'text',
        'labelSize'
    ],
    components: {
    
    },
    data() {
        return {
            topIcon: true
        }
    },
    computed:{
       
    },
    methods: {
    }
});
