
import { Vue } from 'vue-property-decorator';
import TableFilter from '@/components/table/table-filter/table-filter.vue';
import TableView from '@/components/table/table-view/table-view.vue';
import memos from '@/json/memos.json';
import { ProcessDto } from '@/dto/types';
export default Vue.extend({
    name: 'Memo',
    components: {
        TableFilter,
        TableView,
    },
    data() {
        return {
            myMemos: memos,
            process_affected: new Array<ProcessDto>(),
            fields: [
                { key: "case_number", label: "Id demande", sortable: false },
                { key: "name", label: "Nom du instance", sortable: false },
                { key: "owner", label: "Auteur", sortable: false},
                { key: "beneficiary", label: "Bénéficiaire", sortable: false },
                { key: "affected_to", label: "Affecté à", sortable: false},
                { key: "type", label: "Type instance", sortable: false},
                { key: "description", label: "Description", sortable: false},
                { key: "category", label: "Catégorie instance", sortable: false},
                { key: "service", label: "Service gestion", sortable: false},
                { key: "state", label: "Etat", sortable: false},
            ],
            processFilter: {
                service: 'service',
                state: 'state',
                category: 'category',
                group: 'group'
            },
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: []
        }
    },
    methods: {
        filtered() {
            console.log('filtering')
        },
        goToAnotherPage(page: string) {
            this.$router.push(page);
        },
        tableRowClicked(item: any){
            console.log(item)
            this.goToAnotherPage(`/memo_management/`)
        },
    }
});
