
import { Vue } from 'vue-property-decorator';
import { mapGetters } from "vuex";
import FormInput from '@/components/form/form-input/form-input.vue';
import FormSelect from '@/components/form/form-select/form-select.vue';
import formTextarea from '@/components/form/form-textarea/form-textarea.vue';
import fields from '@/json/form_2.json';
import { FieldModel } from '@/models/field/field';
export default Vue.extend({
    name: 'Test',
    props:[
        'case'
    ],
    components: {
        FormInput,
        formTextarea,
        FormSelect
    },
    data() {
        return {
            formField: {} as FieldModel,
            formFields: new Array<FieldModel>(),
            form: this.case? this.case : {firstname: '', lastname: ''}
        }
    },
    computed:{
        ...mapGetters('instance', ['getProcessCase'])
    },
    methods: {
    },
    mounted(){
        this.formFields = fields
        console.log('item: ', this.case)
    }
});
