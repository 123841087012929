
import { Vue } from "vue-property-decorator";
export default Vue.extend({
    name: 'InputText',
    props: [
        'label', 'inputId', 'value',
        'disabled', 'validationData',
        'size', 'placeholder',
        'labelCols',
        'contentCols',
        'optionCols',
        'labelAlign'
    ],
    data() {
        return {
            data: this.value,
            inputState: ''
        }
    },
    computed: {
        state(): boolean | null {
            if (this.inputState === 'true'){
                return true
            }else if(this.inputState === 'false'){
                return false
            }
            return null
        }
    },
    methods: {
        onChangeValue () {
            this.$emit('onChange', this.data)
        },
        validateState(state: boolean): boolean{
            return state
        },
        disableColor(){
            console.log('disableColor')
        },
        disableFontWeigth(){
            console.log('disableFontWeigth')
        },
    },
    watch: {
        'validationData':  function validateInput(state: string){
                    this.inputState = state
                },
        data() {
            this.$emit("input", this.data)
        },
        value() {
            if(this.value){
                this.data = this.value
            }
        }
    }
})
