
import { Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default Vue.extend({
    name: 'FormVueSelect',
    components: {
        vSelect
    },
    props: [
        'formGroupId',
        'label',
        'textToshowInSelect',
        'formSelectId',
        'value',
        'options',
        'inputSize',
        'disabled',
        'width',
        'bus',
        'labelCols'
    ],
    data() {
        return {
            data: this.value,
            inputState: ''
        }
    },
    computed: {
        state(): boolean | null {
            if (this.inputState === 'true'){
                return true
            }else if(this.inputState === 'false'){
                return false
            }
            return null
        }
    },
    methods: {
        onChangeValue () {
            this.$emit('onChange', this.data)
        },
        validateState(state: boolean): boolean{
            return state
        }
    },
    watch: {
        'validationData':  function validateInput(state: string){
            this.inputState = state
        },
        data() {
            console.log('watch data: ',this.value)
            this.$emit("input", this.data)
        },
        value() {
            console.log('watch value: ',this.value)
            if(this.value){
                this.data = this.value
            }
        },
        options() {
            console.log('options changed : ', this.options)
        }
    },
    mounted(){
        console.log(this.value)
    }
})
