import { Group } from '@/models/identity/group';
import { PersonModel } from '@/models/proposition';
import { KeycloakService } from '@/services/keycloak.service';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { KeycloakState } from './types';
import { groupMixin } from '@/mixins/group.mixin';
import { UserModel } from '@/models/person/person';

export const actions: ActionTree<KeycloakState, RootState> = {
  loadKeycloakProcessManagerData(context) {
    KeycloakService.getInstance(this.getters.getUser.access_token)
      .loadKeycloackGroupsData(this.getters.getUser.tenant)
      .then((response) => {
        if (response.data) {
          const processManagerGroup = response.data!.find(
            (value) =>
              value.attributes['tenant'][0] === this.getters.getUser.tenant, // tenant ne doit pas etre un []
          );
          if (processManagerGroup) {
            const processGroups = groupMixin.methods.getGroupInSubGroupByPath(
              '/' +
                processManagerGroup['name'] +
                '/Applications/process-manager',
              processManagerGroup,
            );
            if (processGroups !== null)
              context.dispatch('setGroups', processGroups.sub_groups);

            const processServices = groupMixin.methods.getGroupInSubGroupByPath(
              '/' + processManagerGroup['name'] + '/Organization',
              processManagerGroup,
            );
            if (processServices !== null)
              context.dispatch(
                'setBusinessServices',
                processServices.sub_groups,
              );
          }
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadKeycloakUsers(context) {
    KeycloakService.getInstance(this.getters.getUser.access_token)
      .loadUsers(this.getters.getUser.tenant)
      .then((response) => {
        if (response.data) {
          context.dispatch('setUsers', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  setKeycloakProcessManagerData(context, payload: Group | undefined) {
    context.commit('setKeycloakProcessManagerData', payload);
  },

  setGroups(context, payload: Array<Group>) {
    context.commit('setGroups', payload);
  },

  setGroup(context, payload: Group | undefined) {
    context.commit('setGroup', payload);
  },

  setBusinessServices(context, payload: Array<Group>) {
    context.commit('setBusinessServices', payload);
  },

  setBusinessService(context, payload: Group | undefined) {
    context.commit('setBusinessService', payload);
  },

  setGroupMembers(context, payload: Array<PersonModel>) {
    context.commit('setGroupMembers', payload);
  },

  setGroupMember(context, payload: PersonModel | undefined) {
    context.commit('setGroupMember', payload);
  },

  setUsers(context, payload: Array<UserModel>) {
    context.commit('setUsers', payload);
  },
};
