import { MutationTree } from 'vuex';
import { TaskState } from './types';
import { Task, TaskComment, TaskGroupsAssignedModel, TaskWithVariables } from '@/models/task/task';
import { Case } from '@/models/process/case';

export const mutations: MutationTree<TaskState> = {
  setTasks(state, payload: Array<TaskWithVariables>) {
    state.tasks = payload;
  },

  // setTasksForCase(state, payload: string){
  //   state.tasksForCase = []
  //   state.tasks.forEach((task: Task) => {
  //     if(task.processInstanceId === payload){
  //       state.tasksForCase.push(task)
  //     }
  //   })
  // },

  setTask(state, payload: TaskWithVariables | undefined) {
    state.task = payload;
  },

  setCases(state, payload: Array<Case>) {
    state.cases = payload;
  },

  setCase(state, payload: Case | undefined) {
    state.case = payload;
  },

  setComment(state, payload: TaskComment | undefined) {
    state.comment = payload;
  },

  setComments(state, payload: Array<TaskComment>) {
    state.comments = payload;
  },

  addCommentToComments(state, payload: TaskComment) {
    state.comments.push(payload)
  },

  setTaskGroupsAssignee(state, payload: Array<TaskGroupsAssignedModel>){
    state.taskGroupsAssignee = payload
  },

  setTaskGroupsAssigneeOverride(state, payload: TaskGroupsAssignedModel){
    state.taskGroupsAssignee.push(payload)
  }
};
