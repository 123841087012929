
import { Vue } from 'vue-property-decorator';
import { appMixin } from '@/mixins/app.mixin';
import InputTextarea from '@/components/form/form-textarea/input-textarea.vue';
import Chatbox from './chatbox/chatbox.vue';
import 'vue-select/dist/vue-select.css';
import { mapActions, mapGetters } from 'vuex';
export default Vue.extend({
  name: 'ProcessNote',
  props: [
    'process_instance_id'
  ],
  mixins: [appMixin],
  components: {
    InputTextarea,
    Chatbox,
  },
  data() {
    return {
      internalNote: '',
      noteForApplicant: '',
      files: [],
      fileToShow: ['derick.jpg', 'lucas.jpg'],
      attachments: [{}],
      refresh: 0,
    };
  },
  computed: {
    ...mapGetters('processNotes', ['getMessages']),
    ...mapGetters('task', ['getTask']),
    ...mapGetters('instance', ['getProcessInstance']),
    ...mapGetters(["getUser"]),
  },
  methods: {
    ...mapActions('processNotes', ['setMessage']),
    ...mapActions('task', ['addComment']),

    onMessageSend() {
      if (this.messageValidation() === null) {
        if (this.internalNote !== '') {
            this.addComment({
            id: this.getProcessInstance.current_task.id,
            processInstanceId: this.getProcessInstance.process_instance.id,
            message: 'intern:'+this.internalNote,
            user: this.getUser.email
          }).then(()=>{
            this.internalNote = ''
          });
        } else {
          this.addComment({
            id: this.getProcessInstance.current_task.id,
            processInstanceId: this.getProcessInstance.process_instance.id,
            message: 'extern:'+this.noteForApplicant,
            user: this.getUser.email
          }).then(()=>{
            this.noteForApplicant = ''
          });
        }
      }
    },

    sendComment() {
      this.addComment({
        id: this.getProcessInstance.current_task.id,
        processInstanceId: this.getProcessInstance.process_instance.id,
        message: this.internalNote,
        user: this.getUser.email
      }).then(()=>{
        this.internalNote = ''
        this.noteForApplicant= ''
      });
    },
    choose() {
      let file = document.createElement('input');

      file.type = 'file';
      file.accept = (this as any).accept;
      file.multiple = true;
      file.click();

      const files: File[] = [];

      file.onchange = () => {
        if (file.files) {
          for (let i = 0; i < file.files.length; i++) {
            const currentFile = file.files.item(i);
            if (currentFile) {
              files.push(currentFile);
            }
          }
        }
        this.uploadImage(files);
      };
    },
    messageValidation() {
      if (this.internalNote === '' && this.noteForApplicant === '') {
        return this.makeToast(
          'danger',
          "Veuillez saisir un message dans l'un des champs.",
          'Erreur: Champ vide',
          5000,
        );
      }
      if (this.internalNote !== '' && this.noteForApplicant !== '') {
        return this.makeToast(
          'danger',
          'Veuillez saisir le message dans un seul champ.',
          'Erreur: Tous les champs remplis',
          5000,
        );
      }
      return null;
    },
    makeToast(variant: string, message: string, title: string, delay: number) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: delay,
      });
    },
  },
});
