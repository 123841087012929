
import { Vue } from "vue-property-decorator";
import {mapActions, mapGetters} from 'vuex'
import { appMixin } from '@/mixins/app.mixin';
import { TaskGroupsAssignedModel, Task } from '@/models/task/task';
import {Variable} from "@/models/process/variable";
import { ProcessInstance } from "@/models/process/process-instance";
import AppCollapse from '@/components/app-collapse/app-collapse.vue'
import TextWithLabel from '@/components/text-with-label/text-with-label.vue';
import Badge from '@/components/badge/badge.vue'

export default Vue.extend({
    name: "ProcessSidebar",
    props: {
        process: {
            type: Object as () => ProcessInstance,
            default: null,
            required: false
        },
        task:{
            type: Object as () => Task,
            default: null,
            required: false
        },
        variables:{
            type: Array as () => Variable[],
            default: null,
            required: false
        }
    },
    // props: [
    //     'process',
    //     'task',
    //     'variables'
    // ],
    mixins: [appMixin],
    components: {
        AppCollapse,
        TextWithLabel,
        Badge
    },
    data(){
        return {
            selected: '',
            showGroupSelectInput: false,
            labelText: 'ou groupe bénéficiaire',
            affectedTo: '',
            affectedGroup: '',
        }
    },
    computed: {
        ...mapGetters('keycloak', ['getUsersDropdown', 'getGroups', 'getGroupsDropdown']),
        ...mapGetters('task', ['getTaskGroupsAssignee']),
        ...mapGetters('instance', ['getProcessInstance']),
        processStartDate(): string{
            if(!this.process){
                return '-'
            }
            return this.process.startTime
        },
        processEndDate(): string{
            if(!this.process){
                return '-'
            }
            return this.process.endTime
        },
        processStatus(): string{
            if(!this.process){
                return '-'
            }
            return this.formatProcessState(this.process.state)
        },
        processBusinessKey(): string{
            if(!this.process){
                return  this.task.processDefinitionId.substring(
                            0,
                            this.task.processDefinitionId.indexOf(':')
                        ) 
            }
            return this.process.businessKey
        },
        
        processStep(): string{
            return this.formatProcessStep(this.task)
        },
        processAssignee(): string{
            return this.instanceAssignee(this.task)
        },
        taskGroups(): string[]{
            return this.getTaskGroupsAssignee.map((group: TaskGroupsAssignedModel) => {
                const _group = this.getGroupsDropdown.find((grp)=> grp.value === group.groupId)
                if((!_group) || (_group === undefined)){
                    return '-'
                }
                return _group.text
            })
        }
    },
    methods: {
        ...mapActions('task', ['addTaskAttachment', 'loadtGroupsAssigned', 'loadTask']),
        onButtonClick(){
            this.$emit('buttonClicked',true)
        },
        onLabelSelectClick(){
            this.showGroupSelectInput = !this.showGroupSelectInput
            if(this.showGroupSelectInput){
                this.labelText = 'ou personnel bénéficiaire'
            }else{
                this.labelText = 'ou groupe bénéficiaire'
            }
        },
        formatApplicantInfo(variables: Array<Variable>, key: string){
            const data = variables.find((variable: any) => variable.name === key)
            return data ? data.value : 'N/A'
        },
        formatProcessStep(payload: Task | undefined | null){
            if(!payload || payload === undefined){
                return '-'
            }else{
                return payload.name
            }
        },
        choose(){
            let file = document.createElement('input');

            file.type = 'file';
            file.accept = (this as any).accept;
            file.multiple = true;
            file.click();

            const files: File[] = [];

            file.onchange = () => {
                if (file.files) {
                for (let i = 0; i < file.files.length; i++) {
                        const currentFile = file.files.item(i);
                        if (currentFile) {
                            files.push(currentFile);
                        }
                    }
                }
                console.log('files: ', files)
                const data = {
                    task_id: this.getProcessInstance.current_task.id,
                    file_name: this.formatFileName(),
                    files: files
                }
                this.addTaskAttachment(data);
            };
        },
        formatFileName(){
            return this.getProcessInstance.process_instance.businessKey
        },
        uploadImage(files: File[]) {
            let name = '';
            if(this.mediatheque) name = this.imageName;
            else name = (this as any).buildName(this.filename);
            const payload = (this as any).buildPayload(
                name,
                this.tags,
                files,
            );
            this.createMedias(payload).then((value) => {
                if (value.data) {
                    if (this.isSingle) 
                        this.medias = value.data;
                    else 
                        this.medias = value.data;
                }
                if (value.apiError) {
                    Vue.$toast("Erreur lors de l'upload");
                }
            });
        },
    },
    mounted(){
        this.loadtGroupsAssigned(this.task.id)
        //this.loadTask(this.$route.params.id)
    }
});
