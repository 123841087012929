import { ProcessService } from '@/services/process.service';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { ProcessState } from './types';

export const actions: ActionTree<ProcessState, RootState> = {
  async loadProcessesDefinition(context) {
    await ProcessService.getInstance(this.getters.getUser.access_token)
      .loadProcessesDefinition()
      .then((response) => {
        if (response.data) {
          context.commit('setProcessesDefinition', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadProcess(context, payload: string) {
    ProcessService.getInstance(this.getters.getUser.access_token)
      .loadProcessById(payload)
      .then((response) => {
        if (response.data) {
          context.commit('setProcess', response.data);
          return response.data;
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },
};
