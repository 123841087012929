import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ProcessState } from './types';
import { ProcessDefinitionEntity } from '@/models/process/process-definition';

export const getters: GetterTree<ProcessState, RootState> = {
  getProcessesDefinition(state): ProcessDefinitionEntity[] {
    console.log('getProcessesDefinition', state.processesDefinition);
    return state.processesDefinition;
  },

  getProcess(state): any | undefined {
    return state.process;
  },
};
