
import { Vue } from 'vue-property-decorator';
export default Vue.extend({
    name: 'PanelInfo',
    props: [
        'width',
        'text'
    ],
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    }
});
