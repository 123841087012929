import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { layout } from './modules/layout/index';
import { processNotes } from './modules/process-notes/index';
import { auth } from './modules/auth';
import { routeRedirect } from './modules/route-redirect';
import { process } from './modules/process';
import { keycloak } from './modules/keycloak';
import { task } from './modules/task';
import { instance } from './modules/instance';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
  },
  modules: {
    layout: layout,
    processNotes: processNotes,
    auth: auth,
    process: process,
    keycloak: keycloak,
    task: task,
    routeRedirect: routeRedirect,
    instance: instance,
  },
  devtools: false,
};

export default new Vuex.Store<RootState>(store);
