
import { Vue } from 'vue-property-decorator';
import { mapGetters } from "vuex";
import FormInput from '@/components/form/form-input/form-input.vue';
import FormTextarea from '@/components/form/form-textarea/form-textarea.vue';
import { appMixin } from '@/mixins/app.mixin';
export default Vue.extend({
    name: 'ProcessForm',
    props:[
        'instance',
        'variables'
    ],
    components: {
        FormInput,
        FormTextarea,
    },
    mixins: [appMixin],
    data() {
        return {
    
        }
    },
    computed:{
        ...mapGetters('instance', ['getProcessInstance']),
        formElementToShow(): Array<Record<string, string>>{
            const excludedElement = [
                this.PROCESS_VAR_FIRSTNAME, 
                this.PROCESS_VAR_LASTNAME, 
                this.PROCESS_VAR_PHONE,
                this.PROCESS_VAR_MAIL, 
                this.PROCESS_VAR_TYPE
            ]
            const elements = []
            this.variables.map((element: Record<string, string>) => {
                if((!excludedElement.includes(element.name))){
                    elements.push(element)
                }
            })
            return elements
        },
    },
    methods: {
        checkTextType(value: string | null): number{
            if(!value){
                return this.TEXT_INPUT_DIGIT
            } 
            if(value.length < this.TEXT_INPUT_DIGIT){
                return this.TEXT_INPUT_DIGIT
            }else{
                return this.TEXT_AREA_DIGIT
            }
        },
    },
});
