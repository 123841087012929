export const processServicesApiUrl =
  process.env.VUE_APP_API_PROCESS_SERVICES_URL;
export const orchestratorUrl = process.env.VUE_APP_ORCHESTRATOR_URL;
export const referentialApiUrl = process.env.VUE_APP_API_REFERENTIAL_URL;

export default {
  processServicesApiUrl,
  orchestratorUrl,
  referentialApiUrl,
};
