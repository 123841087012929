import { HttpBaseService } from '@/services/httpBase.service';
import { referentialApiUrl } from '@/app.config';
import { ApiResponse } from '@/models/http/ApiResponse';
import { Group } from '@/models/identity/group';

export class KeycloakService extends HttpBaseService {
  private static classInstance?: KeycloakService;
  static apiBaseUrl = referentialApiUrl;

  constructor(token: string) {
    super(token, KeycloakService.apiBaseUrl);
  }

  public static getInstance(token: string): KeycloakService {
    if (!this.classInstance) {
      this.classInstance = new KeycloakService(token);
    }
    return this.classInstance;
  }

  public async loadKeycloackGroupsData(
    payload: string,
  ): Promise<ApiResponse<Group[]>> {
    const apiResponse = new ApiResponse<Group[]>();
    const requestUrl =
      referentialApiUrl +
      '/keycloak-service/get-groups?tenant=' +
      payload;
    try {
      const response = await this.instance.get(requestUrl);
      apiResponse.data = response.data;
      return apiResponse;
    } catch (error) {
      apiResponse.apiError = error.response.data;
      return apiResponse;
    }
  }

  public async loadUsers(payload: string): Promise<ApiResponse<any>> {
    const apiResponse = new ApiResponse<any>();
    const requestUrl =
      referentialApiUrl + '/keycloak-service/get-users?tenant=' + payload;
    try {
      const response = await this.instance.get(requestUrl);
      apiResponse.data = response.data;
      return apiResponse;
    } catch (error) {
      apiResponse.apiError = error.response.data;
      return apiResponse;
    }
  }
}
