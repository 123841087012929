import { Group } from '@/models/identity/group';

export const groupMixin = {
  methods: {
    getGroupInSubGroupByPath(path: string, group: Group): Group | null {
      for (const g of group.sub_groups) {
        if (g.sub_groups.length > 0) {
          const result = this.getGroupInSubGroupByPath(path, g);
          if (result !== null) {
            return result;
          }
        }
        if (g.path === path) {
          return g;
        }
      }
      return null;
    },
  },
};
