
import { Vue } from 'vue-property-decorator';
import FormInput from '@/components/form/form-input/form-input.vue';
import FormVueSelect from '@/components/form/form-select/form-v-select.vue';
import moment from 'moment/moment';
//import vSelect from "vue-select";
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
    name: 'ProcessDefaultInfo',
    props:[
        'processCase'
    ],
    components: {
        FormInput,
        FormVueSelect,
    },
    data() {
        return {
            step: '',
            affectedGroup: this.processCase.metadata.activeTaskAssignee,
            affectedTo: this.processCase.metadata.activeTaskAssignee,
            caseNumber: '',
            status: '',
            openedDate: '',
            showGroupSelectInput: false,
            labelText: 'ou affecter à un groupe'
        }
    },
    computed:{
        ...mapGetters('task', ['getTask']),
        ...mapGetters('instance', ['getProcessCase']),
        ...mapGetters('keycloak', ['getUsersDropdown', 'getGroups']),
    },
    methods: {
        ...mapActions('task', ['assignUserToTask']),
        onLabelSelectClick(){
            this.showGroupSelectInput = !this.showGroupSelectInput
            if(this.showGroupSelectInput){
                this.labelText = 'ou affecter à un agent'
            }else{
                this.labelText = 'ou affecter à un groupe'
            }
        },
        onAssigneeChange(payload: string){
            this.affected_to = payload
            console.log('payload: ', payload)
            this.assignUserToTask({
                userId: payload,
                taskId: this.processCase.metadata.activeTaskId
            }).then(()=>{
                this.goToAnotherPage('/request_management')
            })
        },
        goToAnotherPage(page: string) {
            this.$router.push(page);
        },
        formatDate(payload: string): string{
            return moment(payload).format('DD-MM-YYYY')
        }
    },
    mounted(){
        console.log('props: ', this.processCase)
    }
});
