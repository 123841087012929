import { messageModel } from '@/models/messages/messages';
import { MutationTree } from 'vuex';
import { MessageState } from "./types";

export const mutations: MutationTree<MessageState> = {

    setMessages(state, payload: Array<messageModel>) {
        state.messages = payload
    },

    setMessage(state, payload: messageModel | undefined) {
        if(payload)
            state.messages.push(payload) 
    }
};