import { ProcessService } from '@/services/process.service';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { InstanceState } from './types';
import { Case } from '@/models/process/case';
import { TaskService } from '@/services/task.service';
import { Task } from '@/models/task/task';
import { Comment } from '@/models/comment/comment';
import { ProcessInstanceEntity } from '@/models/process/process-instance';
import { ProcessInstanceService } from '@/services/process-instance.service';
import { ProcessState } from '@/common/api.enums';
import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";

export const actions: ActionTree<InstanceState, RootState> = {
  async loadProcessInstances(context) {
    ProcessService.getInstance(this.getters.getUser.access_token)
      .loadProcessInstances()
      .then((response) => {
        if (response.data) {
          context.commit('setProcessInstances', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadProcessInstance(context) {
    ProcessService.getInstance(this.getters.getUser.access_token)
      .loadProcessInstances()
      .then((response) => {
        if (response.data) {
          context.commit('setProcessInstances', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  loadProcessInstanceById(context, payload: string) {
    ProcessService.getInstance(this.getters.getUser.access_token)
      .loadProcessInstanceById(payload)
      .then((response) => {
        if (response.data) {
          context.commit('setProcessInstance', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  completeProcessCase(context) {
    const tasks = context.rootGetters['task/getTasksForCase'];
    tasks.forEach((task: Task) => {
      TaskService.getInstance(this.getters.getUser.access_token)
        .completeTask(task.processInstanceId)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
          }
          if (response.apiError) {
            console.log('error');
          }
        });
    });
  },

  frontProcessCaseDataRefresh(context, payload: Case) {
    context.commit('frontProcessCaseDataRefresh', payload);
  },

  loadProcessInstanceComments(context, payload: string) {
    ProcessInstanceService.getInstance(this.getters.getUser.access_token)
      .loadComments(payload)
      .then((response) => {
        if (response.data) {
          context.commit('setProcessInstanceComments', response.data);
        }
        if (response.apiError) {
          console.log('error');
        }
      });
  },

  activateProcessInstance(context, payload: string){
    ProcessInstanceService.getInstance(this.getters.getUser.access_token)
      .activateProcessInstance(payload)
      .then((response) => {
        if (response.apiError) {
          console.log('error');
        }else{
          context.commit('updateProcessInstanceState', {
            id: payload,
            state: ProcessState.Active
          });
        }
      })
  },

  cancelProcessInstance(context, payload: string){
    ProcessInstanceService.getInstance(this.getters.getUser.access_token)
      .cancelProcessInstance(payload)
      .then((response) => {
        if (response.apiError) {
          console.log('error');
        }else{
          context.commit('updateProcessInstanceState', {
            id: payload,
            state: ProcessState.Cancel
          });
        }
      })
  },

  closeProcessInstance(context, payload: string){
    ProcessInstanceService.getInstance(this.getters.getUser.access_token)
      .closeProcessInstance(payload)
      .then((response) => {
        if (response.apiError) {
          Vue.$toast("Erreur lors de la clôture administrative de la procedure!", { type: TYPE.ERROR });
        }else{
          Vue.$toast("Clôturer administrative de la procedure effectuer avec succès!");
          context.commit('updateProcessInstanceState', {
            id: payload,
            state: ProcessState.Close
          });
        }
      })
  },

  selectedProcessInstance(context, payload: ProcessInstanceEntity) {
    context.commit('setProcessInstance', payload);
  },
  
  addComment(context, payload: Comment) {
    context.commit('addCommentToComments', payload);
  },
};
