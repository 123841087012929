
import { Vue } from "vue-property-decorator";
import InputSelect from '@/components/form/form-select/input-select.vue';
import InputText from '@/components/form/form-input/input-text.vue';
import { FormType, TypeOptions } from "@/common/api.enums";
export default Vue.extend({
    name: 'FormElementCreation',
    components: {
        InputSelect,
        InputText
    },
    props: [
        'id',
        'reference',
    ],
    data() {
        return { 
            field: {
                name: '',
                value: '',
                label: '',
                type: '',
                placeholder: '',
                option: [
                    {
                        text: '',
                        value: ''
                    }
                ],
                position: '',
                form: ''
            },
            typeOptions: [
                {
                    text: 'Texte', value: TypeOptions.Text
                },
                {
                    text: 'Numerique', value: TypeOptions.Num
                },
                {
                    text: 'Date', value: TypeOptions.Date
                },
                {
                    text: 'Heure', value: TypeOptions.Time
                },
                {
                    text: 'Selection', value: TypeOptions.Select
                }
            ],
            formOptions: [
                {
                    text: 'Formulaire client', value: FormType.Client
                },
                {
                    text: 'Formulaire de travail', value: FormType.Work
                }
            ],
        }
    },
    computed: {
    },
    methods: {
    },
})
