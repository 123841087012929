import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { TaskState } from './types'
import { Task, TaskComment, TaskGroupsAssignedModel, TaskWithVariables } from "@/models/task/task";
import { Case } from "@/models/process/case";

export const getters: GetterTree<TaskState, RootState> = {
    
    getTasks(state): Array<TaskWithVariables> {
        return state.tasks
    },

    getTasksForCase(state): Array<TaskWithVariables> {
        return state.tasksForCase
    },

    getTask(state): TaskWithVariables | undefined{
        return state.task
    },

    getCases(state): Array<Case> {
        return state.cases
    },

    getCase(state): Case | undefined{
        return state.case
    },
    
    getComment(state): TaskComment | undefined{
        return state.comment
    },

    getComments(state): Array<TaskComment>{
        return state.comments
    },

    getTaskGroupsAssignee(state): Array<TaskGroupsAssignedModel>{
        return state.taskGroupsAssignee
    }

};