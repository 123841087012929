import moment from 'moment/moment';
import {mapActions, mapGetters} from 'vuex'
import { Task } from '@/models/task/task';
import { Variable } from '@/models/process/variable';

export const appMixin = {
    computed: {
        ...mapGetters('keycloak', ['getUsersDropdown', 'getGroups']),
        ...mapGetters('instance', ['getProcessInstance']),
        instanceAssignee_():string{ 
            if(!this.getProcessInstance.current_task){
                return '-'
            }
            const filtered = this.getUsersDropdown.filter((user) => {
                return user.value === this.getProcessInstance.current_task.assignee
            });
            return filtered[0].text;
        },
        
    },
    data() {
      return {
        TEXT_INPUT_DIGIT: 40,
        TEXT_AREA_DIGIT: 120,
        EXTERNAL_COMMENT_TYPE: 'extern',
        INTERNAL_COMMENT_TYPE: 'intern',
        PROCESS_VAR_FIRSTNAME: 'firstname',
        PROCESS_VAR_LASTNAME: 'lastname',
        PROCESS_VAR_PHONE: 'phone',
        PROCESS_VAR_MAIL: 'mail',
        PROCESS_VAR_TYPE: 'process_type'
      };
    },
    methods: {
        formatDate(payload: string): string{
            if(payload==='-'){
                return '-'
            }
            const date = moment(payload).format('DD-MM-YYYY')
            return date !== 'Invalid date' ? date : '-'
        },
        formatDateTime(payload: string): string{
            if(payload==='-'){
                return '-'
            }
            const date = moment(payload).format('DD-MM-YYYY HH:mm:ss')
            return date !== 'Invalid date' ? date : '-'
        },
        instanceAssignee(payload: Task | undefined | null): string{
            if(!payload || payload === undefined){
                return '-'
            }
            if(!payload.assignee){
                return '-'
            }
            const find = this.getUsersDropdown.find((user)=> user.value === payload.assignee)
            if (find)
                return find.text
            return payload.assignee    
        },
        formatProcessState(payload: string): string{
            let state = ''
            switch (payload){
                case 'ACTIVE':
                    state = 'Ouvert';
                    break;
                case 'COMPLETED':
                    state = 'Traité';
                    break;
                case 'EXTERNALLY_TERMINATED':
                    state = 'Clôture administrative'
                    break;
                default:
                    state = 'Ouvert'
            }
            return state
        },
        formVariableElement(variables: Variable[], keepVar: string[]): Variable[]{
            const elements = []
            variables.map((element: Variable) => {
                if((keepVar.includes(element.name))){
                    elements.push(element)
                }
            })
            return elements
        },
        process_type(payload: Variable | null | undefined): string{
            if(!payload || payload === undefined){
                return '-'
            }
            if(payload.value===''){
                return '-'
            }
            return payload.value
        },
        objectHasProperty(obj: Record<string, unknown>, attr: string): boolean {
            return attr in obj;
        },
        compareDate(firstDate: Date, secondDate: Date): number{
            return moment(firstDate, "YYYY-MM-DD").diff(moment(secondDate, "YYYY-MM-DD"));
        },
        goToAnotherPage(page: string): void {
            this.$router.push(page);
        },
        routerGoBack(): void{
            this.$router.go(-1)
        }
    },
  };