import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/process-notes/process-notes-getters";
import {actions} from "@/store/modules/process-notes/process-notes-actions";
import {mutations} from "@/store/modules/process-notes/process-notes-mutations";
import { MessageState } from "./types";
export const state: MessageState = {
    messages: [],
    message: undefined,
};

export const processNotes: Module<MessageState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};