
import Vue from "vue";
export default Vue.extend({
  name: "NavLink",
  props: ["title", "link", "icon", "subMenus", "showmenu", "isOpen", "id", "containSubmenuToggle"],
  data() {
    return {
      showMenu: this.showmenu,
      childActive: false,
      subMenusO: this.subMenus,
      submenuWidthToggle: false
    };
  },
  methods: {
    enter(el: any) {
      el.style.height = "auto";
      const height = getComputedStyle(el).height;
      el.style.height = 0;
      getComputedStyle(el);
      setTimeout(() => {
        el.style.height = height;
      });
    },
    afterEnter(el: any) {
      el.style.height = "auto";
    },
    leave(el: any) {
      el.style.height = getComputedStyle(el).height;
      getComputedStyle(el);
      setTimeout(() => {
        el.style.height = 0;
      });
    },
    showSubMenu() {
      this.showMenu = !this.showMenu;
    },
    activeChildMenu() {
      this.childActive = true;
    },
    activeMenu(showSubMenu:boolean) {
    return !showSubMenu
    },
  },
  watch: {
    $route() {
      this.childActive = false;
    },
    'containSubmenuToggle':  function submenuToggle(state: boolean){
              this.submenuWidthToggle = state
      },
  },
});
