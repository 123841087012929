
import {Vue} from 'vue-property-decorator';
import Tab from '@/components/tab/tab.vue';
import TabItem from '@/components/tab/tab-item/tab-item.vue';
import Process from '@/components/tab/item-content/process/process.vue';
import AssignmentService from '@/components/tab/item-content/assignment-service/assignment-service.vue';
import AssignmentGroup from '@/components/tab/item-content/assignment-group/assignment-group.vue';
import CaseCategory from '@/components/tab/item-content/case-category/case-category.vue';
import {mapActions} from "vuex";
import { groupMixin } from '@/mixins/group.mixin';


export default Vue.extend({
  name: 'ProcessAdmin',
  components: {
    Tab,
    TabItem,
    Process,
    AssignmentService,
    AssignmentGroup,
    CaseCategory
  },
  mixins: [
    groupMixin
  ],
  data() {
    return {
      tabIndex: 0,
    }
  },
  computed: {
      
  },
  methods: {
    ...mapActions("keycloak", ['loadKeycloakProcessManagerData']),
    ...mapActions("process", ['loadProcessesDefinition']),
  },
  mounted() {
    this.loadProcessesDefinition()
  }
});
