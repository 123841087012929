
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from "vuex";
import TableFilter from '@/components/table/table-filter/table-filter.vue';
import TableView from '@/components/table/table-view/table-view.vue';
import tasks from '@/json/tasks.json';
import moment from 'moment/moment';
import { ProcessDto } from '@/dto/types';
import { TaskWithVariables } from '@/models/task/task';
import { appMixin } from '@/mixins/app.mixin';
export default Vue.extend({
    name: 'Tasks',
    components: {
        TableFilter,
        TableView,
    },
    mixins: [appMixin],
    data() {
        return {
            myTasks: tasks,
            process_affected: new Array<ProcessDto>(),
            fields: [
                { 
                    key: "taskDefinitionKey", label: "Id demande", sortable: false,
                    formatter: (value: null, key: string, item: TaskWithVariables) => {
                        return item.task.taskDefinitionKey
                    },
                    filterByFormatted: true
                },
                { 
                    key: "name", label: "Etape courante", sortable: false,
                    formatter: (value: null, key: string, item: TaskWithVariables) => {
                        return item.task.name
                    },
                    filterByFormatted: true
                },
                { 
                    key: "owner", label: "Auteur", sortable: false,
                    formatter: (value: null, key: string, item: TaskWithVariables) => {
                        return item.task.owner
                    },
                    filterByFormatted: true
                },
                { 
                    key: "type", label: "Type", sortable: false,
                },
                { 
                    key: "description", label: "Description", sortable: false,
                    formatter: (value: null, key: string, item: TaskWithVariables) => {
                        return item.task.description
                    },
                    filterByFormatted: true
                },
                { 
                    key: "assignee", label: "Affecté à", sortable: false,
                    formatter: (value: null, key: string, item: TaskWithVariables) => {
                        return item.task.assignee
                    },
                    filterByFormatted: true
                },
                { 
                    key: "lastUpdated", label: "Date MAJ", sortable: false,
                    formatter: (value: null, key: string, item: TaskWithVariables) => {
                        return item.task ? moment(item.task.lastUpdated).format('YYYY-MM-DD'): ''
                    },
                    filterByFormatted: true
                },
            ],
            // fields: [
            //     { key: "caseInstanceId", label: "Id demande", sortable: false },
            //     { key: "processDefinitionName", label: "Nom du instance", sortable: false },
            //     { key: "owner", label: "Demandeur", sortable: false},
            //     { key: "processDefinitionName", label: "Type", sortable: false},
            //     {
            //         key: "description", label: "Description", sortable: false,
            //         formatter: (value: null, key: string, item: Case) => {
            //             return item.metadata.processDescription
            //         },
            //         filterByFormatted: true
            //     },
            //     { 
            //         key: "category", label: "Catégorie", sortable: false,
            //         formatter: (value: null, key: string, item: Case) => {
            //             return item.metadata.processCategory
            //         },
            //         filterByFormatted: true
            //     },
            //     { key: "service", label: "Service gestion", sortable: false},
            //     { 
            //         key: "affected_to", label: "Affecté à", sortable: false,
            //         formatter: (value: null, key: string, item: Case) => {
            //             return item.metadata.activeTaskAssignee
            //         },
            //         filterByFormatted: true
            //     },
            //     { 
            //         key: "activeStep", label: "Etape courante", sortable: false,
            //         formatter: (value: null, key: string, item: Case) => {
            //             return item.metadata.activeTaskName
            //         },
            //         filterByFormatted: true
            //     },
            //     { key: "state", label: "Statut", sortable: false},
            //     { key: "creation_date", label: "Date d'ouverture", sortable: false },
            //     { key: "update_date", label: "Date MAJ", sortable: false },
            // ],
            processFilter: {
                service: 'service',
                state: 'state',
                category: 'category'
            },
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: []
        }
    },
    computed: {
        ...mapGetters("instance", ['getProcessCases']),
        ...mapGetters('task', ['getTasks']),
        ...mapGetters("process", ['getProcess', 'getProcessesDefinition']),
        sortTable(): TaskWithVariables[] {
            return this.sortData(this.getTasks)
        }
    },
    methods: {
        ...mapActions("process", ['loadProcess', 'loadProcessesDefinition']),
        ...mapActions("instance", ['setProcessCase', 'loadProcessCases']),
        ...mapActions("task", ['loadTasks', 'setTask']),
        filtered() {
            console.log('filtering')
        },
        tableRowClicked(item: TaskWithVariables){
            this.setTask(item)
            this.goToAnotherPage(`/task/${item.task.id}`)
        },
        sortData(payload: TaskWithVariables[]): TaskWithVariables[]{
            return payload.sort((a, b) => {
                return new Date(a.task.lastUpdated).getTime() - new Date(b.task.lastUpdated).getTime();
            })
        }
    },
    created(){
        this.loadTasks()
    }
});
