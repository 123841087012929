
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from "vuex";
import AppHeader from '@/components/app-header/app-header.vue'
import ProcessDefaultInfo from '@/components/process/process-builder/process-default-info/process-default-info.vue'
import ProcessCase from '@/components/process/process-builder/process-case/process-case.vue';
import ProcessNote from '@/components/process/process-builder/process-note/process-note.vue';
import Test from '@/components/process/process-builder/process-case/test.vue';

export default Vue.extend({
    name: 'ProcessUseCase',
    components: {
        AppHeader,
        ProcessDefaultInfo,
        //ProcessCase,
        ProcessNote,
        Test
    },
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters('instance', ['getProcessCase'])
    },
    methods: {
        ...mapActions('instance', ['completeProcessCase']),
        ...mapActions('task', ['setTasksForCase', 'completeTask']),
        onCloseBtnClick(){
            this.completeProcessCase(this.$route.params.id)
        },
        onRegisterBtnClick(){
            this.completeTask(this.getProcessCase.metadata.activeTaskId)
        }
    },
    mounted(){
        this.setTasksForCase(this.$route.params.id);
    }
});
