import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { MessageState } from './types'
import { messageModel } from "@/models/messages/messages";


export const getters: GetterTree<MessageState, RootState> = {
    
    getMessages(state): Array<messageModel> {
        return state.messages
    },

    getMessage(state): messageModel | undefined{
        return state.message
    }
};