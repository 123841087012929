import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout from '../components/layout/layout.vue';
import ProcessAdmin from '../pages/process-admin/process-admin.vue';
import Tasks from '../pages/tasks/tasks.vue';
import RequestManagement from '../pages/request-management/request-management.vue';
import Memo from '../pages/memo/memo.vue';
import ProcessUseCase from '../pages/process-use-case/process-use-case.vue';
import MemoManagement from '../pages/memo-management/memo-management.vue';
import ProcessManagement from '../pages/process-management/process-management.vue';
import PageTest from '../pages/page-test/page-test.vue';
import UseCase from '../pages/process-use-case/use-case.vue';
import UseCaseOverride from '../pages/process-use-case/use-case-override.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {name:'sandbox',path: '/', redirect: '/instance'},
  {
    path: '/layout',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/instance',
        name: 'RequestManagement',
        component: RequestManagement
      },
      {
        path: '/administration',
        name: 'ProcessAdmin',
        component: ProcessAdmin
      },
      {
        path: '/task',
        name: 'Tasks',
        component: Tasks
      },
      {
        path: '/memos',
        name: 'Memo',
        component: Memo
      },
      {
        path: '/process_use_case/:id',
        name: 'ProcessUseCase',
        component: ProcessUseCase
      },
      {
        path: '/page_test',
        name: 'PageTest',
        component: PageTest
      },
      {
        path: '/process_management',
        name: 'ProcessManagement',
        component: ProcessManagement
      },
      {
        path: '/memo_management',
        name: 'MemoManagement',
        component: MemoManagement
      },
      {
        path: '/instance/:id',
        name: 'UseCase',
        component: UseCase
      },
      {
        path: '/task/:id',
        name: 'UseCaseOverride',
        component: UseCaseOverride
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: 'service-app/',
  routes
})

export default router
