
import { Vue } from 'vue-property-decorator';
import TableFilter from '@/components/table/table-filter/table-filter.vue';
import TableView from '@/components/table/table-view/table-view.vue';
import { ProcessDto } from '@/dto/types';
import moment from 'moment/moment';
import { mapGetters, mapActions } from "vuex";
import { appMixin } from '@/mixins/app.mixin';
import {ProcessInstanceEntity} from "@/models/process/process-instance";
export default Vue.extend({
    name: 'RequestManagement',
    components: {
        TableFilter,
        TableView,
    },
    mixins: [appMixin],
    data() {
        return {
            process_affected: new Array<ProcessDto>(),
            fields: [
                { 
                    key: "businessKey", label: "Id demande", sortable: false, 
                    formatter: (value: null, key: string, item: ProcessInstanceEntity) => {
                        return item.process_instance.businessKey
                    },
                    thClass: 'column-width',
                    tdClass: 'column-width' 
                },
                { 
                    key: "owner", label: "Demandeur", sortable: false,
                    formatter: (value: null, key: string, item: ProcessInstanceEntity) => {
                        let firstName = item.variables.find((variable: any) => variable.name === 'firstname')
                        let lastName = item.variables.find((variable: any) => variable.name === 'lastname')
                        if (firstName && lastName) {
                            return firstName.value + ' ' + lastName.value
                        }
                        return ''
                    },
                    filterByFormatted: true,
                    thClass: 'column-width',
                    tdClass: 'column-width'
                },
                { 
                    key: "processDefinitionName", label: "Type", sortable: false, 
                    formatter: (value: null, key: string, item: ProcessInstanceEntity) => {
                        return item.process_instance.processDefinitionName
                    },
                    thClass: 'column-width',
                    tdClass: 'column-width' 
                },
                { 
                    key: "task_affected_to", label: "Affecté à", sortable: false,
                    formatter: (value: null, key: string, item: ProcessInstanceEntity) => {
                        return item.current_task ? item.current_task.assignee : ''
                    },
                    filterByFormatted: true,
                    thClass: 'column-width',
                    tdClass: 'column-width'
                },
                { 
                    key: "activeStep", label: "Etape courante", sortable: false,
                    formatter: (value: null, key: string, item: ProcessInstanceEntity) => {
                        return item.current_task ? item.current_task.name : ''
                    },
                    filterByFormatted: true,
                    thClass: 'column-width',
                    tdClass: 'column-width'
                },
                { 
                    key: "proceesState", label: "Statut", sortable: false, 
                    formatter: (value: null, key: string, item: ProcessInstanceEntity) => {
                        return item.process_instance.state
                    },
                    thClass: 'column-width',
                    tdClass: 'column-width'
                },
                { 
                    key: "startTime", label: "Date d'ouverture", sortable: false,
                    formatter: (value: null, key: string, item: ProcessInstanceEntity) => {
                        return moment(item.process_instance.startTime).format('YYYY-MM-DD')
                    }, 
                    thClass: 'column-width',
                    tdClass: 'column-width'
                },
                { 
                    key: "update_date", label: "Date MAJ", sortable: false,
                    formatter: (value: null, key: string, item: ProcessInstanceEntity) => {
                        return item.current_task ? moment(item.current_task.lastUpdated).format('YYYY-MM-DD'): ''
                    },
                    thClass: 'column-width',
                    tdClass: 'column-width'
                },
            ],
            processFilter: {
                service: 'service',
                state: 'state',
                category: 'category',
                group: 'group'
            },
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: true,
            filterIncludeFile: [],
            anotherPage: '/process_use_case'
        }
    },
    computed: {
        ...mapGetters("instance", ['getProcessInstances']),
        filteredItems() {
            const filtered = this.getProcessInstances.filter((item) => {
                return (
                    (!this.getFilterStatut || item.state === this.getFilterStatut) &&
                    (
                        !this.getFilterSearchText || this.getFilterSearchText.trim() === '' ||
                        item.name.toLowerCase().includes(this.getFilterSearchText) ||
                        item.description.toLowerCase().includes(this.getFilterSearchText)
                    )
                );
            });
            return filtered;
        },
        sortTable(): ProcessInstanceEntity[] {
            return this.sortData(this.getProcessInstances)
        }
    },
    methods: {
        ...mapActions("instance", ['loadProcessInstances', 'selectedProcessInstance']),
        filtered() {
            console.log('filtering')
        },
        formatName(value: string){
            return value.toLowerCase().replace(/\s+/g, '-')
        },
        tableRowClicked(item: ProcessInstanceEntity){
            this.selectedProcessInstance(item).then(()=>{
                this.goToAnotherPage(`/instance/${item.process_instance.id}`)
            })
        },
        sortData(payload: ProcessInstanceEntity[]): ProcessInstanceEntity[]{
            return payload.sort((a, b) => {
                return new Date(a.process_instance.startTime).getTime() - new Date(b.process_instance.startTime).getTime();
            })
        }
    },
    created(){
        this.loadProcessInstances()
    }
});
