
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from "vuex";
import TableFilter from '@/components/table/table-filter/table-filter.vue';
import TableView from '@/components/table/table-view/table-view.vue';
import List from '@/components/modals/list/list.vue';
import group from '@/json/group_data.json';
import member from '@/json/member_data.json';
import {ProcessDto} from '@/dto/types'
export default Vue.extend({
    name: 'AssignmentGroup',
    components: {
        TableFilter,
        TableView,
        List
    },
    data() {
        return {
            memberGroup: member,
            processGroups: group,
            process_affected: new Array<ProcessDto>(),
            fields: [
                { key: "name", label: "Nom du groupe", sortable: false },
                { 
                    key: "description", label: "Description du groupe", sort: false, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.attributes.description ? item.attributes.description[0] : 'Non défini'
                    } 
                },
                { 
                    key: "service", label: "Service de reference", sortable: false, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.attributes.service ? item.attributes.service[0] : 'Non défini'
                    } 
                },
                { key: "affected_process", label: "Processus affectés", sortable: false, tdClass: "center_element" },
                { 
                    key: "members", label: "Liste des membres", sort: false,  tdClass: "center_element",
                    formatter: (value: null, key: string, item: any) => {
                        return item.attributes.members
                    } 
                },
                { 
                    key: "createdAt", label: "Création", sort: false, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.attributes.createdAt
                    } 
                },
                { 
                    key: "updatedAt", label: "Dernière date de mise à jour", sort: false, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.attributes.updatedAt
                    } 
                }
            ],
            processFilter: {
                service: 'service'
            },
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            filterIncludeFile: []
        }
    },
    computed: {
        ...mapGetters("keycloak", ['getGroups']),
    },
    methods: {
        showMemberList(value: any){
            this.memberGroup = value
            this.$bvModal.show('group.ts-member-list')
        },
        showProcessList(value: any){
            this.process_affected = value.process
            this.$bvModal.show('process-group.ts-list')
        },
        showProcessAffectedDetail(process: Record<string, unknown>){
            alert('detail: '+ process.name)
        },
        removeProcessAffected(process: Record<string, unknown>){
            this.process_affected = this.process_affected.filter((item)=> item.name !== process.name)
        },
        showGroupMemberDetail(member: Record<string, unknown>){
            alert('detail: '+ member.name)
        },
        removeGroupMember(member: Record<string, unknown>){
            this.memberGroup = this.memberGroup.filter((item)=> item.name !== member.name)
        },
        filtered() {
            console.log('filtering')
        },
    },
});
