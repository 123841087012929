
import { Vue } from "vue-property-decorator";
import InputSelect from '@/components/form/form-select/input-select.vue';
export default Vue.extend({
    name: 'List',
    components: {
        InputSelect,
    },
    props: [
        'id',
        'reference',
        'title',
        'items',
        'allowDetail',
        'allowRemove',
        'elementsToAdd'
    ],
    data() {
        return { 
            list: this.items,
            elements: this.elementsToAdd,
            selected: ''
        }
    },
    computed: {
    },
    methods: {
        itemDetail(item: Record<string, unknown>) {
            this.$emit('showDetail', item)
        },
        itemRemove(item: Record<string, unknown>) {
            this.$emit('removeItem', item)
        }
    },
    watch: {
        items(){
            this.list = this.items
        }
    }
})
