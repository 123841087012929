import { HttpBaseService } from '@/services/httpBase.service';
import { processServicesApiUrl } from '@/app.config';
import { ApiResponse } from '@/models/http/ApiResponse';
import { Comment } from '@/models/comment/comment';

export class ProcessInstanceService extends HttpBaseService {
  private static classInstance?: ProcessInstanceService;
  static apiBaseUrl = processServicesApiUrl;

  constructor(token: string) {
    super(token, ProcessInstanceService.apiBaseUrl);
  }

  public static getInstance(token: string): ProcessInstanceService {
    if (!this.classInstance) {
      this.classInstance = new ProcessInstanceService(token);
    }

    return this.classInstance;
  }

  

  public loadComments(payload: string): Promise<ApiResponse<Comment[]>> {
    const apiResponse = new ApiResponse<Comment[]>();
    return this.instance
      .get(`/engine-rest/process-instance/${payload}/comment`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public newComment(
    payload: Record<string, string>,
  ): Promise<ApiResponse<Comment>> {
    const apiResponse = new ApiResponse<Comment>();

    const requestData = {
      message: payload.message,
    };
    return this.instance
      .post(`/engine-rest/process-instance/${payload.id}/comment/create`, requestData)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public activateProcessInstance(payload: string): Promise<any>{
    const apiResponse = new ApiResponse<any>();
    const request_data = {
      suspended: false
    }
    return this.instance
      .post(`/engine-rest/process-instance/${payload}/suspended`, request_data)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public cancelProcessInstance(payload: string): Promise<any>{
    const apiResponse = new ApiResponse<any>();
    const request_data = {
      suspended: true
    }
    return this.instance
      .post(`/engine-rest/process-instance/${payload}/suspended`, request_data)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }

  public closeProcessInstance(payload: string): Promise<any>{
    const apiResponse = new ApiResponse<any>();
    return this.instance
      .delete(`/engine-rest/process-instance/${payload}`)
      .then((response) => {
        apiResponse.data = response.data;
        return apiResponse;
      })
      .catch((error) => {
        apiResponse.apiError = error.response.data;
        return apiResponse;
      });
  }
}
