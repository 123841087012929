
import { Vue } from 'vue-property-decorator';
import PanelInfo from './panel-info/panel-info.vue';
export default Vue.extend({
    name: 'AppHeader',
    props: [
        'caseType',
        'id',
        'close',
        'closeLabel',
        'taskId'
    ],
    components: {
        PanelInfo
    },
    data() {
        return {
        }
    },
    methods: {
        oncloseBtnclick(){
            this.$emit('closeBtnclick')
        },
        onRegisterBtnClick(){
            this.$emit('registerBtnclick')
        }
    }
});
