import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/instance/getters';
import { actions } from '@/store/modules/instance/actions';
import { mutations } from '@/store/modules/instance/mutations';
import { InstanceState } from './types';
export const state: InstanceState = {
  instances: [],
  instance: undefined,
  comments: [],
};

export const instance: Module<InstanceState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
