
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from "vuex";


export default Vue.extend({
    name: 'Badge',
    props: [
        'label',
        'text',
        'labelSize',
        'multiple',
        'texts'
    ],
    components: {
    
    },
    data() {
        return {
            topIcon: true
        }
    },
    computed:{
       
    },
    methods: {
    },
});
