
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from "vuex";


export default Vue.extend({
    name: 'AppCollapse',
    props: [
        'title',
        'collapseId'
    ],
    components: {
    
    },
    data() {
        return {
            topIcon: true
        }
    },
    computed:{
       
    },
    methods: {
        onCollapseHeaderClicked(){
            this.$root.$emit('bv::toggle::collapse', this.collapseId)
            this.topIcon = !this.topIcon
        }
    },
    mounted(){
        console.log('app-collapse')
    }
});
