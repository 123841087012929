
import { Vue } from 'vue-property-decorator';
import { appMixin } from '@/mixins/app.mixin';
import Message from './message/message.vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment/moment';
import { Comment } from '@/models/comment/comment';
export default Vue.extend({
    name: 'Chatbox',
    props: [
        'processInstanceId',
        'type'
    ],
    components: {
       Message 
    },
    mixins: [appMixin],
    data() {
        return {
            beneficiary: '',
            contact: '',
            applicant: '',
        }
    },
    computed: {
        ...mapGetters("processNotes", ["getMessages"]),
        ...mapGetters("instance", ["getProcessInstance", "getComments"]),
        messages(): Comment[]{
            return this.getComments.filter((comment: Comment)=> {
                if(this.commentType(comment) === this.type)
                    return comment
            })
        }
    },
    methods: {
        ...mapActions("processNotes", ["setMessages", "setMessage"]),
        ...mapActions('instance', ['loadProcessInstanceComments']),
        messageDate(value: string) {
            return moment(value).format('DD-MM-YYYY HH:mm:ss')
        },
        commentType(comment: Comment): string{
            return comment.message.substring(0, comment.message.indexOf(':'))
        },
        message(content: string): string{
            return content.split(":")[1].trim()
        }
    },
    mounted() {
        this.loadProcessInstanceComments(this.processInstanceId)
    }
});
